// Generated from QueryParser.g4 by ANTLR 4.13.2
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import { ATN, ATNDeserializer, DFA, FailedPredicateException, RecognitionException, NoViableAltException, Parser, ParserATNSimulator, ParserRuleContext, PredictionContextCache, Token } from 'antlr4';
class QueryParser extends Parser {
    get grammarFileName() { return "QueryParser.g4"; }
    get literalNames() { return QueryParser.literalNames; }
    get symbolicNames() { return QueryParser.symbolicNames; }
    get ruleNames() { return QueryParser.ruleNames; }
    get serializedATN() { return QueryParser._serializedATN; }
    createFailedPredicateException(predicate, message) {
        return new FailedPredicateException(this, predicate, message);
    }
    constructor(input) {
        super(input);
        this._interp = new ParserATNSimulator(this, QueryParser._ATN, QueryParser.DecisionsToDFA, new PredictionContextCache());
    }
    // @RuleVersion(0)
    query() {
        let localctx = new QueryContext(this, this._ctx, this.state);
        this.enterRule(localctx, 0, QueryParser.RULE_query);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 24;
                this.expression(0);
                this.state = 25;
                this.match(QueryParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    expression(_p) {
        if (_p === undefined) {
            _p = 0;
        }
        let _parentctx = this._ctx;
        let _parentState = this.state;
        let localctx = new ExpressionContext(this, this._ctx, _parentState);
        let _prevctx = localctx;
        let _startState = 2;
        this.enterRecursionRule(localctx, 2, QueryParser.RULE_expression, _p);
        try {
            let _alt;
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 35;
                this._errHandler.sync(this);
                switch (this._input.LA(1)) {
                    case 3:
                        {
                            localctx = new NotExpressionContext(this, localctx);
                            this._ctx = localctx;
                            _prevctx = localctx;
                            this.state = 28;
                            this.match(QueryParser.NOT);
                            this.state = 29;
                            this.expression(5);
                        }
                        break;
                    case 4:
                        {
                            localctx = new BracketExpressionContext(this, localctx);
                            this._ctx = localctx;
                            _prevctx = localctx;
                            this.state = 30;
                            this.match(QueryParser.LPAREN);
                            this.state = 31;
                            this.expression(0);
                            this.state = 32;
                            this.match(QueryParser.RPAREN);
                        }
                        break;
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        {
                            localctx = new TermExpressionContext(this, localctx);
                            this._ctx = localctx;
                            _prevctx = localctx;
                            this.state = 34;
                            this.term();
                        }
                        break;
                    default:
                        throw new NoViableAltException(this);
                }
                this._ctx.stop = this._input.LT(-1);
                this.state = 45;
                this._errHandler.sync(this);
                _alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
                while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
                    if (_alt === 1) {
                        if (this._parseListeners != null) {
                            this.triggerExitRuleEvent();
                        }
                        _prevctx = localctx;
                        {
                            this.state = 43;
                            this._errHandler.sync(this);
                            switch (this._interp.adaptivePredict(this._input, 1, this._ctx)) {
                                case 1:
                                    {
                                        localctx = new AndExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
                                        this.pushNewRecursionContext(localctx, _startState, QueryParser.RULE_expression);
                                        this.state = 37;
                                        if (!(this.precpred(this._ctx, 4))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
                                        }
                                        this.state = 38;
                                        this.match(QueryParser.AND);
                                        this.state = 39;
                                        this.expression(5);
                                    }
                                    break;
                                case 2:
                                    {
                                        localctx = new OrExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
                                        this.pushNewRecursionContext(localctx, _startState, QueryParser.RULE_expression);
                                        this.state = 40;
                                        if (!(this.precpred(this._ctx, 3))) {
                                            throw this.createFailedPredicateException("this.precpred(this._ctx, 3)");
                                        }
                                        this.state = 41;
                                        this.match(QueryParser.OR);
                                        this.state = 42;
                                        this.expression(4);
                                    }
                                    break;
                            }
                        }
                    }
                    this.state = 47;
                    this._errHandler.sync(this);
                    _alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
                }
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.unrollRecursionContexts(_parentctx);
        }
        return localctx;
    }
    // @RuleVersion(0)
    term() {
        let localctx = new TermContext(this, this._ctx, this.state);
        this.enterRule(localctx, 4, QueryParser.RULE_term);
        try {
            this.state = 53;
            this._errHandler.sync(this);
            switch (this._input.LA(1)) {
                case 7:
                    this.enterOuterAlt(localctx, 1);
                    {
                        this.state = 48;
                        this.noTagTerm();
                    }
                    break;
                case 8:
                    this.enterOuterAlt(localctx, 2);
                    {
                        this.state = 49;
                        this.tagIdTerm();
                    }
                    break;
                case 9:
                    this.enterOuterAlt(localctx, 3);
                    {
                        this.state = 50;
                        this.tagNameTerm();
                    }
                    break;
                case 10:
                    this.enterOuterAlt(localctx, 4);
                    {
                        this.state = 51;
                        this.wordPatternTerm();
                    }
                    break;
                case 11:
                    this.enterOuterAlt(localctx, 5);
                    {
                        this.state = 52;
                        this.wordLengthTerm();
                    }
                    break;
                default:
                    throw new NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    noTagTerm() {
        let localctx = new NoTagTermContext(this, this._ctx, this.state);
        this.enterRule(localctx, 6, QueryParser.RULE_noTagTerm);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 55;
                this.match(QueryParser.NO_TAG);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    tagIdTerm() {
        let localctx = new TagIdTermContext(this, this._ctx, this.state);
        this.enterRule(localctx, 8, QueryParser.RULE_tagIdTerm);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 57;
                this.match(QueryParser.TAG_ID_PREFIX);
                this.state = 58;
                this.tagIdValue();
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    tagIdValue() {
        let localctx = new TagIdValueContext(this, this._ctx, this.state);
        this.enterRule(localctx, 10, QueryParser.RULE_tagIdValue);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 60;
                this.match(QueryParser.TAG_ID);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    tagNameTerm() {
        let localctx = new TagNameTermContext(this, this._ctx, this.state);
        this.enterRule(localctx, 12, QueryParser.RULE_tagNameTerm);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 62;
                this.match(QueryParser.TAG_NAME_PREFIX);
                this.state = 63;
                this.tagNameValue();
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    tagNameValue() {
        let localctx = new TagNameValueContext(this, this._ctx, this.state);
        this.enterRule(localctx, 14, QueryParser.RULE_tagNameValue);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 65;
                this.match(QueryParser.TAG_NAME);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    wordPatternTerm() {
        let localctx = new WordPatternTermContext(this, this._ctx, this.state);
        this.enterRule(localctx, 16, QueryParser.RULE_wordPatternTerm);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 67;
                this.match(QueryParser.WORD_PATTERN_PREFIX);
                this.state = 68;
                this.wordPatternValue();
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    wordPatternValue() {
        let localctx = new WordPatternValueContext(this, this._ctx, this.state);
        this.enterRule(localctx, 18, QueryParser.RULE_wordPatternValue);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 70;
                this.match(QueryParser.WORD_PATTERN);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    wordLengthTerm() {
        let localctx = new WordLengthTermContext(this, this._ctx, this.state);
        this.enterRule(localctx, 20, QueryParser.RULE_wordLengthTerm);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 72;
                this.match(QueryParser.WORD_LENGTH_PREFIX);
                this.state = 73;
                this.wordLengthRange();
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    // @RuleVersion(0)
    wordLengthRange() {
        let localctx = new WordLengthRangeContext(this, this._ctx, this.state);
        this.enterRule(localctx, 22, QueryParser.RULE_wordLengthRange);
        try {
            this.enterOuterAlt(localctx, 1);
            {
                this.state = 75;
                this.match(QueryParser.WORD_LENGTH_RANGE);
            }
        }
        catch (re) {
            if (re instanceof RecognitionException) {
                localctx.exception = re;
                this._errHandler.reportError(this, re);
                this._errHandler.recover(this, re);
            }
            else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localctx;
    }
    sempred(localctx, ruleIndex, predIndex) {
        switch (ruleIndex) {
            case 1:
                return this.expression_sempred(localctx, predIndex);
        }
        return true;
    }
    expression_sempred(localctx, predIndex) {
        switch (predIndex) {
            case 0:
                return this.precpred(this._ctx, 4);
            case 1:
                return this.precpred(this._ctx, 3);
        }
        return true;
    }
    static get _ATN() {
        if (!QueryParser.__ATN) {
            QueryParser.__ATN = new ATNDeserializer().deserialize(QueryParser._serializedATN);
        }
        return QueryParser.__ATN;
    }
}
QueryParser.AND = 1;
QueryParser.OR = 2;
QueryParser.NOT = 3;
QueryParser.LPAREN = 4;
QueryParser.RPAREN = 5;
QueryParser.WHITESPACES = 6;
QueryParser.NO_TAG = 7;
QueryParser.TAG_ID_PREFIX = 8;
QueryParser.TAG_NAME_PREFIX = 9;
QueryParser.WORD_PATTERN_PREFIX = 10;
QueryParser.WORD_LENGTH_PREFIX = 11;
QueryParser.TAG_ID = 12;
QueryParser.TAG_NAME = 13;
QueryParser.WORD_PATTERN = 14;
QueryParser.WORD_LENGTH_RANGE = 15;
QueryParser.EOF = Token.EOF;
QueryParser.RULE_query = 0;
QueryParser.RULE_expression = 1;
QueryParser.RULE_term = 2;
QueryParser.RULE_noTagTerm = 3;
QueryParser.RULE_tagIdTerm = 4;
QueryParser.RULE_tagIdValue = 5;
QueryParser.RULE_tagNameTerm = 6;
QueryParser.RULE_tagNameValue = 7;
QueryParser.RULE_wordPatternTerm = 8;
QueryParser.RULE_wordPatternValue = 9;
QueryParser.RULE_wordLengthTerm = 10;
QueryParser.RULE_wordLengthRange = 11;
QueryParser.literalNames = [null, null,
    null, null,
    "'('", "')'",
    null, "'nt'",
    "'ti:'", "'tn:'",
    "'wp:'", "'wl:'"];
QueryParser.symbolicNames = [null, "AND",
    "OR", "NOT",
    "LPAREN", "RPAREN",
    "WHITESPACES",
    "NO_TAG", "TAG_ID_PREFIX",
    "TAG_NAME_PREFIX",
    "WORD_PATTERN_PREFIX",
    "WORD_LENGTH_PREFIX",
    "TAG_ID", "TAG_NAME",
    "WORD_PATTERN",
    "WORD_LENGTH_RANGE"];
// tslint:disable:no-trailing-whitespace
QueryParser.ruleNames = [
    "query", "expression", "term", "noTagTerm", "tagIdTerm", "tagIdValue",
    "tagNameTerm", "tagNameValue", "wordPatternTerm", "wordPatternValue",
    "wordLengthTerm", "wordLengthRange",
];
QueryParser._serializedATN = [4, 1, 15, 78, 2, 0, 7, 0, 2,
    1, 7, 1, 2, 2, 7, 2, 2, 3, 7, 3, 2, 4, 7, 4, 2, 5, 7, 5, 2, 6, 7, 6, 2, 7, 7, 7, 2, 8, 7, 8, 2, 9, 7, 9, 2,
    10, 7, 10, 2, 11, 7, 11, 1, 0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 3, 1, 36, 8,
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 5, 1, 44, 8, 1, 10, 1, 12, 1, 47, 9, 1, 1, 2, 1, 2, 1, 2, 1, 2, 1,
    2, 3, 2, 54, 8, 2, 1, 3, 1, 3, 1, 4, 1, 4, 1, 4, 1, 5, 1, 5, 1, 6, 1, 6, 1, 6, 1, 7, 1, 7, 1, 8, 1, 8, 1,
    8, 1, 9, 1, 9, 1, 10, 1, 10, 1, 10, 1, 11, 1, 11, 1, 11, 0, 1, 2, 12, 0, 2, 4, 6, 8, 10, 12, 14, 16,
    18, 20, 22, 0, 0, 73, 0, 24, 1, 0, 0, 0, 2, 35, 1, 0, 0, 0, 4, 53, 1, 0, 0, 0, 6, 55, 1, 0, 0, 0, 8, 57,
    1, 0, 0, 0, 10, 60, 1, 0, 0, 0, 12, 62, 1, 0, 0, 0, 14, 65, 1, 0, 0, 0, 16, 67, 1, 0, 0, 0, 18, 70, 1,
    0, 0, 0, 20, 72, 1, 0, 0, 0, 22, 75, 1, 0, 0, 0, 24, 25, 3, 2, 1, 0, 25, 26, 5, 0, 0, 1, 26, 1, 1, 0,
    0, 0, 27, 28, 6, 1, -1, 0, 28, 29, 5, 3, 0, 0, 29, 36, 3, 2, 1, 5, 30, 31, 5, 4, 0, 0, 31, 32, 3, 2,
    1, 0, 32, 33, 5, 5, 0, 0, 33, 36, 1, 0, 0, 0, 34, 36, 3, 4, 2, 0, 35, 27, 1, 0, 0, 0, 35, 30, 1, 0, 0,
    0, 35, 34, 1, 0, 0, 0, 36, 45, 1, 0, 0, 0, 37, 38, 10, 4, 0, 0, 38, 39, 5, 1, 0, 0, 39, 44, 3, 2, 1,
    5, 40, 41, 10, 3, 0, 0, 41, 42, 5, 2, 0, 0, 42, 44, 3, 2, 1, 4, 43, 37, 1, 0, 0, 0, 43, 40, 1, 0, 0,
    0, 44, 47, 1, 0, 0, 0, 45, 43, 1, 0, 0, 0, 45, 46, 1, 0, 0, 0, 46, 3, 1, 0, 0, 0, 47, 45, 1, 0, 0, 0,
    48, 54, 3, 6, 3, 0, 49, 54, 3, 8, 4, 0, 50, 54, 3, 12, 6, 0, 51, 54, 3, 16, 8, 0, 52, 54, 3, 20, 10,
    0, 53, 48, 1, 0, 0, 0, 53, 49, 1, 0, 0, 0, 53, 50, 1, 0, 0, 0, 53, 51, 1, 0, 0, 0, 53, 52, 1, 0, 0, 0,
    54, 5, 1, 0, 0, 0, 55, 56, 5, 7, 0, 0, 56, 7, 1, 0, 0, 0, 57, 58, 5, 8, 0, 0, 58, 59, 3, 10, 5, 0, 59,
    9, 1, 0, 0, 0, 60, 61, 5, 12, 0, 0, 61, 11, 1, 0, 0, 0, 62, 63, 5, 9, 0, 0, 63, 64, 3, 14, 7, 0, 64,
    13, 1, 0, 0, 0, 65, 66, 5, 13, 0, 0, 66, 15, 1, 0, 0, 0, 67, 68, 5, 10, 0, 0, 68, 69, 3, 18, 9, 0, 69,
    17, 1, 0, 0, 0, 70, 71, 5, 14, 0, 0, 71, 19, 1, 0, 0, 0, 72, 73, 5, 11, 0, 0, 73, 74, 3, 22, 11, 0,
    74, 21, 1, 0, 0, 0, 75, 76, 5, 15, 0, 0, 76, 23, 1, 0, 0, 0, 4, 35, 43, 45, 53];
QueryParser.DecisionsToDFA = QueryParser._ATN.decisionToState.map((ds, index) => new DFA(ds, index));
export default QueryParser;
export class QueryContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    expression() {
        return this.getTypedRuleContext(ExpressionContext, 0);
    }
    EOF() {
        return this.getToken(QueryParser.EOF, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_query;
    }
    enterRule(listener) {
        if (listener.enterQuery) {
            listener.enterQuery(this);
        }
    }
    exitRule(listener) {
        if (listener.exitQuery) {
            listener.exitQuery(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitQuery) {
            return visitor.visitQuery(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class ExpressionContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    get ruleIndex() {
        return QueryParser.RULE_expression;
    }
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }
}
export class BracketExpressionContext extends ExpressionContext {
    constructor(parser, ctx) {
        super(parser, ctx.parentCtx, ctx.invokingState);
        super.copyFrom(ctx);
    }
    LPAREN() {
        return this.getToken(QueryParser.LPAREN, 0);
    }
    expression() {
        return this.getTypedRuleContext(ExpressionContext, 0);
    }
    RPAREN() {
        return this.getToken(QueryParser.RPAREN, 0);
    }
    enterRule(listener) {
        if (listener.enterBracketExpression) {
            listener.enterBracketExpression(this);
        }
    }
    exitRule(listener) {
        if (listener.exitBracketExpression) {
            listener.exitBracketExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitBracketExpression) {
            return visitor.visitBracketExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class AndExpressionContext extends ExpressionContext {
    constructor(parser, ctx) {
        super(parser, ctx.parentCtx, ctx.invokingState);
        super.copyFrom(ctx);
    }
    expression_list() {
        return this.getTypedRuleContexts(ExpressionContext);
    }
    expression(i) {
        return this.getTypedRuleContext(ExpressionContext, i);
    }
    AND() {
        return this.getToken(QueryParser.AND, 0);
    }
    enterRule(listener) {
        if (listener.enterAndExpression) {
            listener.enterAndExpression(this);
        }
    }
    exitRule(listener) {
        if (listener.exitAndExpression) {
            listener.exitAndExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitAndExpression) {
            return visitor.visitAndExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class TermExpressionContext extends ExpressionContext {
    constructor(parser, ctx) {
        super(parser, ctx.parentCtx, ctx.invokingState);
        super.copyFrom(ctx);
    }
    term() {
        return this.getTypedRuleContext(TermContext, 0);
    }
    enterRule(listener) {
        if (listener.enterTermExpression) {
            listener.enterTermExpression(this);
        }
    }
    exitRule(listener) {
        if (listener.exitTermExpression) {
            listener.exitTermExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTermExpression) {
            return visitor.visitTermExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class NotExpressionContext extends ExpressionContext {
    constructor(parser, ctx) {
        super(parser, ctx.parentCtx, ctx.invokingState);
        super.copyFrom(ctx);
    }
    NOT() {
        return this.getToken(QueryParser.NOT, 0);
    }
    expression() {
        return this.getTypedRuleContext(ExpressionContext, 0);
    }
    enterRule(listener) {
        if (listener.enterNotExpression) {
            listener.enterNotExpression(this);
        }
    }
    exitRule(listener) {
        if (listener.exitNotExpression) {
            listener.exitNotExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitNotExpression) {
            return visitor.visitNotExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class OrExpressionContext extends ExpressionContext {
    constructor(parser, ctx) {
        super(parser, ctx.parentCtx, ctx.invokingState);
        super.copyFrom(ctx);
    }
    expression_list() {
        return this.getTypedRuleContexts(ExpressionContext);
    }
    expression(i) {
        return this.getTypedRuleContext(ExpressionContext, i);
    }
    OR() {
        return this.getToken(QueryParser.OR, 0);
    }
    enterRule(listener) {
        if (listener.enterOrExpression) {
            listener.enterOrExpression(this);
        }
    }
    exitRule(listener) {
        if (listener.exitOrExpression) {
            listener.exitOrExpression(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitOrExpression) {
            return visitor.visitOrExpression(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class TermContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    noTagTerm() {
        return this.getTypedRuleContext(NoTagTermContext, 0);
    }
    tagIdTerm() {
        return this.getTypedRuleContext(TagIdTermContext, 0);
    }
    tagNameTerm() {
        return this.getTypedRuleContext(TagNameTermContext, 0);
    }
    wordPatternTerm() {
        return this.getTypedRuleContext(WordPatternTermContext, 0);
    }
    wordLengthTerm() {
        return this.getTypedRuleContext(WordLengthTermContext, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_term;
    }
    enterRule(listener) {
        if (listener.enterTerm) {
            listener.enterTerm(this);
        }
    }
    exitRule(listener) {
        if (listener.exitTerm) {
            listener.exitTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTerm) {
            return visitor.visitTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class NoTagTermContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    NO_TAG() {
        return this.getToken(QueryParser.NO_TAG, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_noTagTerm;
    }
    enterRule(listener) {
        if (listener.enterNoTagTerm) {
            listener.enterNoTagTerm(this);
        }
    }
    exitRule(listener) {
        if (listener.exitNoTagTerm) {
            listener.exitNoTagTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitNoTagTerm) {
            return visitor.visitNoTagTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class TagIdTermContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    TAG_ID_PREFIX() {
        return this.getToken(QueryParser.TAG_ID_PREFIX, 0);
    }
    tagIdValue() {
        return this.getTypedRuleContext(TagIdValueContext, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_tagIdTerm;
    }
    enterRule(listener) {
        if (listener.enterTagIdTerm) {
            listener.enterTagIdTerm(this);
        }
    }
    exitRule(listener) {
        if (listener.exitTagIdTerm) {
            listener.exitTagIdTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTagIdTerm) {
            return visitor.visitTagIdTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class TagIdValueContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    TAG_ID() {
        return this.getToken(QueryParser.TAG_ID, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_tagIdValue;
    }
    enterRule(listener) {
        if (listener.enterTagIdValue) {
            listener.enterTagIdValue(this);
        }
    }
    exitRule(listener) {
        if (listener.exitTagIdValue) {
            listener.exitTagIdValue(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTagIdValue) {
            return visitor.visitTagIdValue(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class TagNameTermContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    TAG_NAME_PREFIX() {
        return this.getToken(QueryParser.TAG_NAME_PREFIX, 0);
    }
    tagNameValue() {
        return this.getTypedRuleContext(TagNameValueContext, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_tagNameTerm;
    }
    enterRule(listener) {
        if (listener.enterTagNameTerm) {
            listener.enterTagNameTerm(this);
        }
    }
    exitRule(listener) {
        if (listener.exitTagNameTerm) {
            listener.exitTagNameTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTagNameTerm) {
            return visitor.visitTagNameTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class TagNameValueContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    TAG_NAME() {
        return this.getToken(QueryParser.TAG_NAME, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_tagNameValue;
    }
    enterRule(listener) {
        if (listener.enterTagNameValue) {
            listener.enterTagNameValue(this);
        }
    }
    exitRule(listener) {
        if (listener.exitTagNameValue) {
            listener.exitTagNameValue(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitTagNameValue) {
            return visitor.visitTagNameValue(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class WordPatternTermContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    WORD_PATTERN_PREFIX() {
        return this.getToken(QueryParser.WORD_PATTERN_PREFIX, 0);
    }
    wordPatternValue() {
        return this.getTypedRuleContext(WordPatternValueContext, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_wordPatternTerm;
    }
    enterRule(listener) {
        if (listener.enterWordPatternTerm) {
            listener.enterWordPatternTerm(this);
        }
    }
    exitRule(listener) {
        if (listener.exitWordPatternTerm) {
            listener.exitWordPatternTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitWordPatternTerm) {
            return visitor.visitWordPatternTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class WordPatternValueContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    WORD_PATTERN() {
        return this.getToken(QueryParser.WORD_PATTERN, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_wordPatternValue;
    }
    enterRule(listener) {
        if (listener.enterWordPatternValue) {
            listener.enterWordPatternValue(this);
        }
    }
    exitRule(listener) {
        if (listener.exitWordPatternValue) {
            listener.exitWordPatternValue(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitWordPatternValue) {
            return visitor.visitWordPatternValue(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class WordLengthTermContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    WORD_LENGTH_PREFIX() {
        return this.getToken(QueryParser.WORD_LENGTH_PREFIX, 0);
    }
    wordLengthRange() {
        return this.getTypedRuleContext(WordLengthRangeContext, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_wordLengthTerm;
    }
    enterRule(listener) {
        if (listener.enterWordLengthTerm) {
            listener.enterWordLengthTerm(this);
        }
    }
    exitRule(listener) {
        if (listener.exitWordLengthTerm) {
            listener.exitWordLengthTerm(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitWordLengthTerm) {
            return visitor.visitWordLengthTerm(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
export class WordLengthRangeContext extends ParserRuleContext {
    constructor(parser, parent, invokingState) {
        super(parent, invokingState);
        this.parser = parser;
    }
    WORD_LENGTH_RANGE() {
        return this.getToken(QueryParser.WORD_LENGTH_RANGE, 0);
    }
    get ruleIndex() {
        return QueryParser.RULE_wordLengthRange;
    }
    enterRule(listener) {
        if (listener.enterWordLengthRange) {
            listener.enterWordLengthRange(this);
        }
    }
    exitRule(listener) {
        if (listener.exitWordLengthRange) {
            listener.exitWordLengthRange(this);
        }
    }
    // @Override
    accept(visitor) {
        if (visitor.visitWordLengthRange) {
            return visitor.visitWordLengthRange(this);
        }
        else {
            return visitor.visitChildren(this);
        }
    }
}
