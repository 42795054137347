// Generated from QueryLexer.g4 by ANTLR 4.13.2
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import { ATNDeserializer, DFA, Lexer, LexerATNSimulator, PredictionContextCache, Token } from "antlr4";
class QueryLexer extends Lexer {
    constructor(input) {
        super(input);
        this._interp = new LexerATNSimulator(this, QueryLexer._ATN, QueryLexer.DecisionsToDFA, new PredictionContextCache());
    }
    get grammarFileName() { return "QueryLexer.g4"; }
    get literalNames() { return QueryLexer.literalNames; }
    get symbolicNames() { return QueryLexer.symbolicNames; }
    get ruleNames() { return QueryLexer.ruleNames; }
    get serializedATN() { return QueryLexer._serializedATN; }
    get channelNames() { return QueryLexer.channelNames; }
    get modeNames() { return QueryLexer.modeNames; }
    static get _ATN() {
        if (!QueryLexer.__ATN) {
            QueryLexer.__ATN = new ATNDeserializer().deserialize(QueryLexer._serializedATN);
        }
        return QueryLexer.__ATN;
    }
}
QueryLexer.AND = 1;
QueryLexer.OR = 2;
QueryLexer.NOT = 3;
QueryLexer.LPAREN = 4;
QueryLexer.RPAREN = 5;
QueryLexer.WHITESPACES = 6;
QueryLexer.NO_TAG = 7;
QueryLexer.TAG_ID_PREFIX = 8;
QueryLexer.TAG_NAME_PREFIX = 9;
QueryLexer.WORD_PATTERN_PREFIX = 10;
QueryLexer.WORD_LENGTH_PREFIX = 11;
QueryLexer.TAG_ID = 12;
QueryLexer.TAG_NAME = 13;
QueryLexer.WORD_PATTERN = 14;
QueryLexer.WORD_LENGTH_RANGE = 15;
QueryLexer.EOF = Token.EOF;
QueryLexer.TAG_ID_MODE = 1;
QueryLexer.TAG_NAME_MODE = 2;
QueryLexer.WORD_PATTERN_MODE = 3;
QueryLexer.WORD_LENGTH_MODE = 4;
QueryLexer.channelNames = ["DEFAULT_TOKEN_CHANNEL", "HIDDEN"];
QueryLexer.literalNames = [null, null,
    null, null,
    "'('", "')'",
    null, "'nt'",
    "'ti:'", "'tn:'",
    "'wp:'", "'wl:'"];
QueryLexer.symbolicNames = [null, "AND",
    "OR", "NOT",
    "LPAREN", "RPAREN",
    "WHITESPACES",
    "NO_TAG", "TAG_ID_PREFIX",
    "TAG_NAME_PREFIX",
    "WORD_PATTERN_PREFIX",
    "WORD_LENGTH_PREFIX",
    "TAG_ID", "TAG_NAME",
    "WORD_PATTERN",
    "WORD_LENGTH_RANGE"];
QueryLexer.modeNames = ["DEFAULT_MODE", "TAG_ID_MODE",
    "TAG_NAME_MODE", "WORD_PATTERN_MODE",
    "WORD_LENGTH_MODE",];
QueryLexer.ruleNames = [
    "AND", "OR", "NOT", "LPAREN", "RPAREN", "WHITESPACES", "NO_TAG", "TAG_ID_PREFIX",
    "TAG_NAME_PREFIX", "WORD_PATTERN_PREFIX", "WORD_LENGTH_PREFIX", "WHITESPACE",
    "NUM_CHAR", "ALPHANUM_CHAR", "ALPHANUM_PATTERN_CHAR", "TAG_ID", "TAG_NAME",
    "WORD_PATTERN", "WORD_LENGTH_RANGE",
];
QueryLexer._serializedATN = [4, 0, 15, 167, 6, -1, 6, -1,
    6, -1, 6, -1, 6, -1, 2, 0, 7, 0, 2, 1, 7, 1, 2, 2, 7, 2, 2, 3, 7, 3, 2, 4, 7, 4, 2, 5, 7, 5, 2, 6, 7, 6,
    2, 7, 7, 7, 2, 8, 7, 8, 2, 9, 7, 9, 2, 10, 7, 10, 2, 11, 7, 11, 2, 12, 7, 12, 2, 13, 7, 13, 2, 14, 7,
    14, 2, 15, 7, 15, 2, 16, 7, 16, 2, 17, 7, 17, 2, 18, 7, 18, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 3, 0, 49, 8,
    0, 1, 1, 1, 1, 1, 1, 1, 1, 3, 1, 55, 8, 1, 1, 2, 1, 2, 1, 2, 1, 2, 3, 2, 61, 8, 2, 1, 3, 1, 3, 1, 4, 1, 4,
    1, 5, 4, 5, 68, 8, 5, 11, 5, 12, 5, 69, 1, 5, 1, 5, 1, 6, 1, 6, 1, 6, 1, 7, 1, 7, 1, 7, 1, 7, 1, 7, 1, 7,
    1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 8, 1, 9, 1, 9, 1, 9, 1, 9, 1, 9, 1, 9, 1, 10, 1, 10, 1, 10, 1, 10, 1, 10,
    1, 10, 1, 11, 1, 11, 1, 12, 1, 12, 1, 13, 1, 13, 1, 14, 1, 14, 1, 15, 4, 15, 110, 8, 15, 11, 15, 12,
    15, 111, 1, 15, 1, 15, 1, 16, 4, 16, 117, 8, 16, 11, 16, 12, 16, 118, 1, 16, 1, 16, 1, 17, 4, 17,
    124, 8, 17, 11, 17, 12, 17, 125, 1, 17, 1, 17, 1, 18, 4, 18, 131, 8, 18, 11, 18, 12, 18, 132, 1,
    18, 1, 18, 1, 18, 1, 18, 4, 18, 139, 8, 18, 11, 18, 12, 18, 140, 1, 18, 4, 18, 144, 8, 18, 11, 18,
    12, 18, 145, 1, 18, 1, 18, 1, 18, 1, 18, 1, 18, 1, 18, 1, 18, 4, 18, 155, 8, 18, 11, 18, 12, 18,
    156, 1, 18, 4, 18, 160, 8, 18, 11, 18, 12, 18, 161, 3, 18, 164, 8, 18, 1, 18, 1, 18, 0, 0, 19, 5,
    1, 7, 2, 9, 3, 11, 4, 13, 5, 15, 6, 17, 7, 19, 8, 21, 9, 23, 10, 25, 11, 27, 0, 29, 0, 31, 0, 33, 0,
    35, 12, 37, 13, 39, 14, 41, 15, 5, 0, 1, 2, 3, 4, 4, 2, 0, 9, 9, 32, 32, 1, 0, 48, 57, 5, 0, 45, 45,
    48, 57, 65, 90, 95, 95, 97, 122, 5, 0, 42, 42, 48, 57, 63, 63, 65, 90, 97, 122, 173, 0, 5, 1, 0,
    0, 0, 0, 7, 1, 0, 0, 0, 0, 9, 1, 0, 0, 0, 0, 11, 1, 0, 0, 0, 0, 13, 1, 0, 0, 0, 0, 15, 1, 0, 0, 0, 0, 17,
    1, 0, 0, 0, 0, 19, 1, 0, 0, 0, 0, 21, 1, 0, 0, 0, 0, 23, 1, 0, 0, 0, 0, 25, 1, 0, 0, 0, 1, 35, 1, 0, 0,
    0, 2, 37, 1, 0, 0, 0, 3, 39, 1, 0, 0, 0, 4, 41, 1, 0, 0, 0, 5, 48, 1, 0, 0, 0, 7, 54, 1, 0, 0, 0, 9, 60,
    1, 0, 0, 0, 11, 62, 1, 0, 0, 0, 13, 64, 1, 0, 0, 0, 15, 67, 1, 0, 0, 0, 17, 73, 1, 0, 0, 0, 19, 76, 1,
    0, 0, 0, 21, 82, 1, 0, 0, 0, 23, 88, 1, 0, 0, 0, 25, 94, 1, 0, 0, 0, 27, 100, 1, 0, 0, 0, 29, 102, 1,
    0, 0, 0, 31, 104, 1, 0, 0, 0, 33, 106, 1, 0, 0, 0, 35, 109, 1, 0, 0, 0, 37, 116, 1, 0, 0, 0, 39, 123,
    1, 0, 0, 0, 41, 163, 1, 0, 0, 0, 43, 44, 5, 97, 0, 0, 44, 45, 5, 110, 0, 0, 45, 49, 5, 100, 0, 0, 46,
    47, 5, 38, 0, 0, 47, 49, 5, 38, 0, 0, 48, 43, 1, 0, 0, 0, 48, 46, 1, 0, 0, 0, 49, 6, 1, 0, 0, 0, 50,
    51, 5, 111, 0, 0, 51, 55, 5, 114, 0, 0, 52, 53, 5, 124, 0, 0, 53, 55, 5, 124, 0, 0, 54, 50, 1, 0,
    0, 0, 54, 52, 1, 0, 0, 0, 55, 8, 1, 0, 0, 0, 56, 57, 5, 110, 0, 0, 57, 58, 5, 111, 0, 0, 58, 61, 5,
    116, 0, 0, 59, 61, 5, 33, 0, 0, 60, 56, 1, 0, 0, 0, 60, 59, 1, 0, 0, 0, 61, 10, 1, 0, 0, 0, 62, 63,
    5, 40, 0, 0, 63, 12, 1, 0, 0, 0, 64, 65, 5, 41, 0, 0, 65, 14, 1, 0, 0, 0, 66, 68, 3, 27, 11, 0, 67,
    66, 1, 0, 0, 0, 68, 69, 1, 0, 0, 0, 69, 67, 1, 0, 0, 0, 69, 70, 1, 0, 0, 0, 70, 71, 1, 0, 0, 0, 71, 72,
    6, 5, 0, 0, 72, 16, 1, 0, 0, 0, 73, 74, 5, 110, 0, 0, 74, 75, 5, 116, 0, 0, 75, 18, 1, 0, 0, 0, 76,
    77, 5, 116, 0, 0, 77, 78, 5, 105, 0, 0, 78, 79, 5, 58, 0, 0, 79, 80, 1, 0, 0, 0, 80, 81, 6, 7, 1, 0,
    81, 20, 1, 0, 0, 0, 82, 83, 5, 116, 0, 0, 83, 84, 5, 110, 0, 0, 84, 85, 5, 58, 0, 0, 85, 86, 1, 0,
    0, 0, 86, 87, 6, 8, 2, 0, 87, 22, 1, 0, 0, 0, 88, 89, 5, 119, 0, 0, 89, 90, 5, 112, 0, 0, 90, 91, 5,
    58, 0, 0, 91, 92, 1, 0, 0, 0, 92, 93, 6, 9, 3, 0, 93, 24, 1, 0, 0, 0, 94, 95, 5, 119, 0, 0, 95, 96,
    5, 108, 0, 0, 96, 97, 5, 58, 0, 0, 97, 98, 1, 0, 0, 0, 98, 99, 6, 10, 4, 0, 99, 26, 1, 0, 0, 0, 100,
    101, 7, 0, 0, 0, 101, 28, 1, 0, 0, 0, 102, 103, 7, 1, 0, 0, 103, 30, 1, 0, 0, 0, 104, 105, 7, 2, 0,
    0, 105, 32, 1, 0, 0, 0, 106, 107, 7, 3, 0, 0, 107, 34, 1, 0, 0, 0, 108, 110, 3, 29, 12, 0, 109, 108,
    1, 0, 0, 0, 110, 111, 1, 0, 0, 0, 111, 109, 1, 0, 0, 0, 111, 112, 1, 0, 0, 0, 112, 113, 1, 0, 0, 0,
    113, 114, 6, 15, 5, 0, 114, 36, 1, 0, 0, 0, 115, 117, 3, 31, 13, 0, 116, 115, 1, 0, 0, 0, 117, 118,
    1, 0, 0, 0, 118, 116, 1, 0, 0, 0, 118, 119, 1, 0, 0, 0, 119, 120, 1, 0, 0, 0, 120, 121, 6, 16, 5,
    0, 121, 38, 1, 0, 0, 0, 122, 124, 3, 33, 14, 0, 123, 122, 1, 0, 0, 0, 124, 125, 1, 0, 0, 0, 125,
    123, 1, 0, 0, 0, 125, 126, 1, 0, 0, 0, 126, 127, 1, 0, 0, 0, 127, 128, 6, 17, 5, 0, 128, 40, 1, 0,
    0, 0, 129, 131, 3, 29, 12, 0, 130, 129, 1, 0, 0, 0, 131, 132, 1, 0, 0, 0, 132, 130, 1, 0, 0, 0, 132,
    133, 1, 0, 0, 0, 133, 134, 1, 0, 0, 0, 134, 135, 5, 46, 0, 0, 135, 136, 5, 46, 0, 0, 136, 138, 1,
    0, 0, 0, 137, 139, 3, 29, 12, 0, 138, 137, 1, 0, 0, 0, 139, 140, 1, 0, 0, 0, 140, 138, 1, 0, 0, 0,
    140, 141, 1, 0, 0, 0, 141, 164, 1, 0, 0, 0, 142, 144, 3, 29, 12, 0, 143, 142, 1, 0, 0, 0, 144, 145,
    1, 0, 0, 0, 145, 143, 1, 0, 0, 0, 145, 146, 1, 0, 0, 0, 146, 147, 1, 0, 0, 0, 147, 148, 5, 46, 0,
    0, 148, 149, 5, 46, 0, 0, 149, 164, 1, 0, 0, 0, 150, 151, 5, 46, 0, 0, 151, 152, 5, 46, 0, 0, 152,
    154, 1, 0, 0, 0, 153, 155, 3, 29, 12, 0, 154, 153, 1, 0, 0, 0, 155, 156, 1, 0, 0, 0, 156, 154, 1,
    0, 0, 0, 156, 157, 1, 0, 0, 0, 157, 164, 1, 0, 0, 0, 158, 160, 3, 29, 12, 0, 159, 158, 1, 0, 0, 0,
    160, 161, 1, 0, 0, 0, 161, 159, 1, 0, 0, 0, 161, 162, 1, 0, 0, 0, 162, 164, 1, 0, 0, 0, 163, 130,
    1, 0, 0, 0, 163, 143, 1, 0, 0, 0, 163, 150, 1, 0, 0, 0, 163, 159, 1, 0, 0, 0, 164, 165, 1, 0, 0, 0,
    165, 166, 6, 18, 5, 0, 166, 42, 1, 0, 0, 0, 18, 0, 1, 2, 3, 4, 48, 54, 60, 69, 111, 118, 125, 132,
    140, 145, 156, 161, 163, 6, 6, 0, 0, 5, 1, 0, 5, 2, 0, 5, 3, 0, 5, 4, 0, 4, 0, 0];
QueryLexer.DecisionsToDFA = QueryLexer._ATN.decisionToState.map((ds, index) => new DFA(ds, index));
export default QueryLexer;
