/*! SearchBuilder 1.8.1
 * ©SpryMedia Ltd - datatables.net/license/mit
 */
import Criteria from './criteria';
import Group from './group';
let $;
export function setJQuery(jq) {
    $ = jq;
}
class SearchBuilder {
    constructor(opts) {
        this.classes = $.extend(true, {}, SearchBuilder.classes);
        this.c = $.extend(true, {}, SearchBuilder.defaults, opts);
        this.dom = {
            clearAll: $('<button type="button">' + this.c.i18n.clearAll + '</button>')
                .addClass(this.classes.clearAll)
                .addClass(this.classes.button)
                .attr('type', 'button'),
            container: $('<div/>')
                .addClass(this.classes.container),
            title: $('<div/>')
                .addClass(this.classes.title),
            titleRow: $('<div/>')
                .addClass(this.classes.titleRow),
            topGroup: undefined
        };
        this.s = {
            specialData: { tags: [] },
            topGroup: undefined
        };
        this._setUp();
        return this;
    }
    getDetails() {
        return this.s.topGroup
            ? this.s.topGroup.getDetails()
            : {};
    }
    getNode() {
        return this.dom.container;
    }
    rebuild(details, redraw = true) {
        this.dom.clearAll.trigger('click');
        if (details === undefined || details === null) {
            return this;
        }
        this.s.topGroup.s.preventRedraw = true;
        this.s.topGroup.rebuild(details);
        this.s.topGroup.s.preventRedraw = false;
        this._checkClear();
        this.s.topGroup.redrawContents();
        if (redraw) {
            //this.s.dt.draw(false);
        }
        this.s.topGroup.setListeners();
        return this;
    }
    _setUp(loadState = true) {
        this.s.topGroup = new Group(this, this.c, undefined, undefined, undefined, undefined, this.s.specialData);
        this._setClearListener();
        /*
        this.s.dt.on('stateSaveParams.dtsb', (e, settings, data) => {
            data.searchBuilder = this.getDetails();
            if(!data.scroller) {
                data.page = this.s.dt.page();
            }
            else {
                data.start = this.s.dt.state().start;
            }
        });
        */
        /*
        this.s.dt.on('stateLoadParams.dtsb', (e, settings, data) => {
            this.rebuild(data.searchBuilder);
        });
        */
        this._build();
        /*
        if (loadState) {
            let loadedState = this.s.dt.state.loaded();

            // If the loaded State is not null rebuild based on it for statesave
            if (loadedState !== null && loadedState.searchBuilder !== undefined) {
                this.s.topGroup.rebuild(loadedState.searchBuilder);
                this.s.topGroup.dom.container.trigger('dtsb-redrawContents');

                // If using SSP we want to restrict the amount of server calls that take place
                //  and this information will already have been processed
                if (!this.s.dt.page.info().serverSide) {
                    if(loadedState.page) {
                        this.s.dt.page(loadedState.page).draw('page');
                    }
                    else if(this.s.dt.scroller && loadedState.scroller) {
                        this.s.dt.scroller().scrollToRow(loadedState.scroller.topRow);
                    }
                }

                this.s.topGroup.setListeners();
            }
            // Otherwise load any predefined options
            else if (this.c.preDefined !== false) {
                this.c.preDefined = this._applyPreDefDefaults(this.c.preDefined);
                this.rebuild(this.c.preDefined);
            }
        }
        */
        this._setEmptyListener();
        //this.s.dt.state.save();
    }
    buildQuery() {
        if (this.s.topGroup === undefined) {
            return '';
        }
        return this.s.topGroup.buildQuery().q;
    }
    _build() {
        this.dom.clearAll.remove();
        this.dom.container.empty();
        this.dom.titleRow.append(this.dom.title);
        this.dom.container.append(this.dom.titleRow);
        this.dom.topGroup = this.s.topGroup.getNode();
        this.dom.container.append(this.dom.topGroup);
        this._setRedrawListener();
        /*
        this.s.dt.on('destroy.dtsb', () => {
            this.dom.container.remove();
            this.dom.clearAll.remove();

            let searchIdx = $.fn.dataTable.ext.search.indexOf(this.s.search);

            while (searchIdx !== -1) {
                $.fn.dataTable.ext.search.splice(searchIdx, 1);
                searchIdx = $.fn.dataTable.ext.search.indexOf(this.s.search);
            }

            this.s.dt.off('.dtsb');
            $(this.s.dt.table().node()).off('.dtsb');
        });
        */
    }
    _checkClear() {
        if (this.s.topGroup.s.criteria.length > 0) {
            this.dom.clearAll.insertAfter(this.dom.title);
            this._setClearListener();
        }
        else {
            this.dom.clearAll.remove();
        }
    }
    _setClearListener() {
        this.dom.clearAll
            .off('click')
            .on('click.dtsb', () => {
            this.s.topGroup = new Group(this, this.c, undefined, undefined, undefined, undefined, this.s.specialData);
            this._build();
            this.s.topGroup.setListeners();
            this.dom.clearAll.remove();
            this._setEmptyListener();
            return false;
        });
    }
    _setRedrawListener() {
        this.s.topGroup.dom.container
            .off('dtsb-redrawContents')
            .on('dtsb-redrawContents.dtsb', () => {
            this._checkClear();
            this.s.topGroup.redrawContents();
            this.s.topGroup.setupLogic();
            this._setEmptyListener();
            // If using SSP we want to restrict the amount of server calls that take place
            //  and this information will already have been processed
            /*
            if (!this.s.dt.page.info().serverSide) {
                this.s.dt.draw();
            }
            */
            //this.s.dt.state.save();
        })
            .off('dtsb-redrawContents-noDraw')
            .on('dtsb-redrawContents-noDraw.dtsb', () => {
            this._checkClear();
            this.s.topGroup.s.preventRedraw = true;
            this.s.topGroup.redrawContents();
            this.s.topGroup.s.preventRedraw = false;
            this.s.topGroup.setupLogic();
            this._setEmptyListener();
        })
            .off('dtsb-redrawLogic')
            .on('dtsb-redrawLogic.dtsb', () => {
            this.s.topGroup.redrawLogic();
        })
            .off('dtsb-add')
            .on('dtsb-add.dtsb', () => {
            this._checkClear();
        })
            .off('dtsb-clearContents')
            .on('dtsb-clearContents.dtsb', () => {
            this._setUp(false);
            //this.s.dt.draw();
        });
        /*
        this.s.dt.on('postEdit.dtsb postCreate.dtsb postRemove.dtsb', () => {
            this.s.topGroup.redrawContents();
        });
        */
    }
    _setEmptyListener() {
        this.s.topGroup.dom.add.on('click.dtsb', () => this._checkClear());
        this.s.topGroup.dom.container.on('dtsb-destroy.dtsb', () => this.dom.clearAll.remove());
    }
}
SearchBuilder.classes = {
    button: 'dtsb-button',
    clearAll: 'dtsb-clearAll',
    container: 'dtsb-searchBuilder',
    inputButton: 'dtsb-iptbtn',
    title: 'dtsb-title',
    titleRow: 'dtsb-titleRow'
};
SearchBuilder.defaults = {
    conditions: {
        'tag': Criteria.tagConditions,
        'word-length': Criteria.wordLengthConditions,
        'word-pattern': Criteria.wordPatternConditions
    },
    depthLimit: false,
    enterSearch: false,
    greyscale: false,
    liveSearch: true,
    i18n: {
        add: 'Add Condition',
        button: {
            0: 'Search Builder',
            _: 'Search Builder (%d)',
        },
        clearAll: 'Clear All',
        condition: 'Condition',
        conditions: {
            tag: {
                empty: 'Empty',
                equals: 'Equals'
            },
            wordLength: {
                equals: 'Equals',
                between: 'Between',
                gte: 'Greater Than Equal To',
                lte: 'Less Than Equal To'
            },
            wordPattern: {
                is: 'Is'
            }
        },
        data: 'Filter',
        delete: '&times',
        deleteTitle: 'Delete filtering rule',
        filters: {
            tag: 'Tag',
            wordPattern: 'Word Pattern',
            wordLength: 'Word Length',
        },
        left: '<',
        leftTitle: 'Outdent criteria',
        logicAnd: 'And',
        logicOr: 'Or',
        logicNot: 'Not',
        right: '>',
        rightTitle: 'Indent criteria',
        search: 'Search',
        title: {
            0: 'Custom Search Builder',
            _: 'Custom Search Builder (%d)',
        },
        value: 'Value',
        valueJoiner: 'and'
    },
    logic: 'AND',
    orthogonal: {
        display: 'display',
        search: 'filter',
    },
    preDefined: false,
    queryBuilt: undefined
};
export default SearchBuilder;
