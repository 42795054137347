export function arraysEqual(a, b) {
    return (a == null && b == null)
        || (a.length === b.length && a.every((value, index) => value === b[index]));
}
export function url(base, search) {
    const url = new URL(base);
    const params = new URLSearchParams();
    Object.entries(search).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(value => params.append(key + '[]', value.toString()));
        }
        else if (value !== undefined && value !== null) {
            params.append(key, value.toString());
        }
    });
    url.search = params.toString();
    return url;
}
