/*! SearchBuilder 1.8.1
 * ©SpryMedia Ltd - datatables.net/license/mit
 */
export function throttle(fn, freq) {
    const frequency = freq !== undefined ? freq : 200;
    let last = null;
    let timer = null;
    return function () {
        var that = this, now = +new Date(), args = arguments;
        if (last && now < last + frequency) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = undefined;
                fn.apply(that, args);
            }, frequency);
        }
        else {
            last = now;
            fn.apply(that, args);
        }
    };
}
