/*! SearchBuilder 1.8.1
 * ©SpryMedia Ltd - datatables.net/license/mit
 */
import Criteria from './criteria';
let $;
export function setJQuery(jq) {
    $ = jq;
}
class Group {
    constructor(queryBuilder, opts, topGroup, index = 0, isChild = false, depth = 1, specialData = undefined) {
        this.classes = $.extend(true, {}, Group.classes);
        this.c = $.extend(true, {}, Group.defaults, opts);
        this.s = {
            criteria: [],
            depth,
            index,
            isChild,
            logic: undefined,
            opts,
            preventRedraw: false,
            queryBuilder,
            specialData,
            toDrop: undefined,
            topGroup
        };
        this.dom = {
            add: $('<button/>')
                .addClass(this.classes.add)
                .addClass(this.classes.button)
                .attr('type', 'button'),
            clear: $('<button>&times</button>')
                .addClass(this.classes.button)
                .addClass(this.classes.clearGroup)
                .attr('type', 'button'),
            container: $('<div/>')
                .addClass(this.classes.group),
            logic: $('<button><div/></button>')
                .addClass(this.classes.logic)
                .addClass(this.classes.button)
                .attr('type', 'button'),
            logicContainer: $('<div/>')
                .addClass(this.classes.logicContainer),
            search: $('<button/>')
                .addClass(this.classes.search)
                .addClass(this.classes.button)
                .attr('type', 'button')
                .css('display', 'none'),
        };
        if (this.s.topGroup === undefined) {
            this.s.topGroup = this.dom.container;
        }
        this._setup();
        return this;
    }
    destroy() {
        this.dom.add.off('.dtsb');
        this.dom.logic.off('.dtsb');
        this.dom.search.off('.dtsb');
        this.dom.container
            .trigger('dtsb-destroy')
            .remove();
        this.s.criteria = [];
    }
    getDetails() {
        if (this.s.criteria.length === 0) {
            return {};
        }
        const details = {
            criteria: [],
            logic: this.s.logic
        };
        for (const crit of this.s.criteria) {
            details.criteria.push(crit.criteria.getDetails());
        }
        return details;
    }
    getNode() {
        return this.dom.container;
    }
    rebuild(loadedDetails) {
        if (loadedDetails.criteria === undefined
            || loadedDetails.criteria === null
            || Array.isArray(loadedDetails.criteria)
                && loadedDetails.criteria.length === 0) {
            return;
        }
        this.s.logic = loadedDetails.logic;
        this.dom.logic.children().first().html(this.s.logic === 'OR'
            ? this.c.i18n.logicOr
            : this.s.logic === 'NOT'
                ? this.c.i18n.logicNot
                : this.c.i18n.logicAnd);
        if (Array.isArray(loadedDetails.criteria)) {
            for (const crit of loadedDetails.criteria) {
                if (crit.logic !== undefined) {
                    this._addPrevGroup(crit);
                }
                else {
                    this._addPrevCriteria(crit);
                }
            }
        }
        for (const crit of this.s.criteria) {
            if (crit.criteria instanceof Criteria) {
                crit.criteria.updateArrows(this.s.criteria.length > 1);
                this._setCriteriaListeners(crit.criteria);
            }
        }
    }
    redrawContents() {
        if (this.s.preventRedraw) {
            return;
        }
        this.dom.container.children().detach();
        this.dom.container
            .append(this.dom.logicContainer)
            .append(this.dom.add);
        if (!this.c.liveSearch) {
            this.dom.container.append(this.dom.search);
        }
        this.s.criteria.sort(function (a, b) {
            if (a.criteria.s.index < b.criteria.s.index) {
                return -1;
            }
            else if (a.criteria.s.index > b.criteria.s.index) {
                return 1;
            }
            return 0;
        });
        this.setListeners();
        for (let i = 0; i < this.s.criteria.length; i++) {
            const crit = this.s.criteria[i].criteria;
            if (crit instanceof Criteria) {
                this.s.criteria[i].index = i;
                this.s.criteria[i].criteria.s.index = i;
                this.s.criteria[i].criteria.dom.container.insertBefore(this.dom.add);
                this._setCriteriaListeners(crit);
                this.s.criteria[i].criteria.s.preventRedraw = this.s.preventRedraw;
                this.s.criteria[i].criteria.rebuild(this.s.criteria[i].criteria.getDetails());
                this.s.criteria[i].criteria.s.preventRedraw = false;
            }
            else if (crit instanceof Group && crit.s.criteria.length > 0) {
                this.s.criteria[i].index = i;
                this.s.criteria[i].criteria.s.index = i;
                this.s.criteria[i].criteria.dom.container.insertBefore(this.dom.add);
                crit.s.preventRedraw = this.s.preventRedraw;
                crit.redrawContents();
                crit.s.preventRedraw = false;
                this._setGroupListeners(crit);
            }
            else {
                this.s.criteria.splice(i, 1);
                i--;
            }
        }
        this.setupLogic();
    }
    redrawLogic() {
        for (const crit of this.s.criteria) {
            if (crit.criteria instanceof Group) {
                crit.criteria.redrawLogic();
            }
        }
        this.setupLogic();
    }
    setupLogic() {
        this.dom.logicContainer.remove();
        this.dom.clear.remove();
        if (this.s.criteria.length < 1) {
            if (!this.s.isChild) {
                this.dom.container.trigger('dtsb-destroy');
                this.dom.container.css('margin-left', 0);
            }
            this.dom.search.css('display', 'none');
            return;
        }
        this.dom.clear.height('0px');
        this.dom.logicContainer.append(this.dom.clear);
        if (!this.s.isChild) {
            this.dom.search.css('display', 'inline-block');
        }
        this.dom.container.prepend(this.dom.logicContainer);
        for (const crit of this.s.criteria) {
            if (crit.criteria instanceof Criteria) {
                crit.criteria.setupButtons();
            }
        }
        let height = this.dom.container.outerHeight() - 1;
        this.dom.logicContainer.width(height);
        this._setLogicListener();
        this.dom.container.css('margin-left', this.dom.logicContainer.outerHeight(true));
        let logicOffset = this.dom.logicContainer.offset();
        let currentLeft = logicOffset.left;
        let groupLeft = this.dom.container.offset().left;
        let shuffleLeft = currentLeft - groupLeft;
        let newPos = currentLeft - shuffleLeft - this.dom.logicContainer.outerHeight(true);
        this.dom.logicContainer.offset({ left: newPos });
        let firstCrit = this.dom.logicContainer.next();
        let currentTop = logicOffset.top;
        let firstTop = $(firstCrit).offset().top;
        let shuffleTop = currentTop - firstTop;
        let newTop = currentTop - shuffleTop;
        this.dom.logicContainer.offset({ top: newTop });
        this.dom.clear.outerHeight(this.dom.logicContainer.height());
        this._setClearListener();
    }
    setListeners() {
        this.dom.add
            .off('click')
            .on('click.dtsb', () => {
            if (!this.s.isChild) {
                this.dom.container.prepend(this.dom.logicContainer);
            }
            this.addCriteria();
            this.dom.container.trigger('dtsb-add');
            //this.s.dt.state.save();
            return false;
        });
        /*
        this.dom.search
            .off('click.dtsb')
            .on('click.dtsb', () => {
                this.s.dt.draw();
            });
        */
        for (let crit of this.s.criteria) {
            crit.criteria.setListeners();
        }
        this._setClearListener();
        this._setLogicListener();
    }
    addCriteria(crit = null) {
        let index = crit === null ? this.s.criteria.length : crit.s.index;
        let criteria = new Criteria(this.s.queryBuilder, this.s.opts, this.s.topGroup, index, this.s.depth, this.s.specialData, this.c.liveSearch);
        if (crit !== null) {
            criteria.c = crit.c;
            criteria.s = crit.s;
            criteria.s.depth = this.s.depth;
            criteria.classes = crit.classes;
        }
        criteria.populate();
        let inserted = false;
        for (let i = 0; i < this.s.criteria.length; i++) {
            if (i === 0 && this.s.criteria[i].criteria.s.index > criteria.s.index) {
                criteria.getNode().insertBefore(this.s.criteria[i].criteria.dom.container);
                inserted = true;
            }
            else if (i < this.s.criteria.length - 1
                && this.s.criteria[i].criteria.s.index < criteria.s.index
                && this.s.criteria[i + 1].criteria.s.index > criteria.s.index) {
                criteria.getNode().insertAfter(this.s.criteria[i].criteria.dom.container);
                inserted = true;
            }
        }
        if (!inserted) {
            criteria.getNode().insertBefore(this.dom.add);
        }
        this.s.criteria.push({
            criteria,
            index
        });
        this.s.criteria = this.s.criteria.sort((a, b) => a.criteria.s.index - b.criteria.s.index);
        for (const opt of this.s.criteria) {
            if (opt.criteria instanceof Criteria) {
                opt.criteria.updateArrows(this.s.criteria.length > 1);
            }
        }
        this._setCriteriaListeners(criteria);
        criteria.setListeners();
        this.setupLogic();
    }
    checkFilled() {
        for (const crit of this.s.criteria) {
            if (crit.criteria instanceof Criteria && crit.criteria.s.filled
                || crit.criteria instanceof Group && crit.criteria.checkFilled()) {
                return true;
            }
        }
        return false;
    }
    count() {
        let count = 0;
        for (const crit of this.s.criteria) {
            if (crit.criteria instanceof Group) {
                count += crit.criteria.count();
            }
            else {
                count++;
            }
        }
        return count;
    }
    buildQuery() {
        let joiner;
        switch (this.s.logic) {
            case 'AND':
                joiner = ' and ';
                break;
            case 'OR':
                joiner = ' or ';
                break;
            case 'NOT':
                joiner = ' or ';
                break;
        }
        let q = '';
        let j = false;
        for (const crit of this.s.criteria) {
            let s;
            if (crit.criteria instanceof Criteria) {
                const c = crit.criteria;
                const cq = c.buildQuery();
                if (cq !== null && cq !== '') {
                    s = cq;
                }
            }
            else if (crit.criteria instanceof Group) {
                const g = crit.criteria;
                const gq = g.buildQuery();
                if (gq.q !== null && gq.q !== '') {
                    s = gq.j ? '(' + gq.q + ')' : gq.q;
                }
            }
            if (s !== undefined) {
                const e = q === '';
                q = e ? s : q + joiner + s;
                j || (j = !e);
            }
        }
        if (this.s.logic === 'NOT' && q !== '') {
            q = j ? 'not (' + q + ')' : 'not ' + q;
            j = false;
        }
        return { q: q, j: j };
    }
    _addPrevGroup(loadedGroup) {
        let idx = this.s.criteria.length;
        let group = new Group(this.s.queryBuilder, this.c, this.s.topGroup, idx, true, this.s.depth + 1, this.s.specialData);
        this.s.criteria.push({
            criteria: group,
            index: idx,
            logic: group.s.logic
        });
        group.rebuild(loadedGroup);
        this.s.criteria[idx].criteria = group;
        this.s.topGroup.trigger('dtsb-redrawContents');
        this._setGroupListeners(group);
    }
    _addPrevCriteria(loadedCriteria) {
        let idx = this.s.criteria.length;
        let criteria = new Criteria(this.s.queryBuilder, this.s.opts, this.s.topGroup, idx, this.s.depth);
        criteria.populate();
        this.s.criteria.push({
            criteria,
            index: idx
        });
        criteria.s.preventRedraw = this.s.preventRedraw;
        criteria.rebuild(loadedCriteria);
        criteria.s.preventRedraw = false;
        this.s.criteria[idx].criteria = criteria;
        if (!this.s.preventRedraw) {
            this.s.topGroup.trigger('dtsb-redrawContents');
        }
    }
    _removeCriteria(criteria, group = false) {
        if (this.s.criteria.length <= 1 && this.s.isChild) {
            this.destroy();
        }
        else {
            let last;
            for (let i = 0; i < this.s.criteria.length; i++) {
                if (this.s.criteria[i].index === criteria.s.index &&
                    (!group || this.s.criteria[i].criteria instanceof Group)) {
                    last = i;
                }
            }
            if (last !== undefined) {
                this.s.criteria.splice(last, 1);
            }
            for (let i = 0; i < this.s.criteria.length; i++) {
                this.s.criteria[i].index = i;
                this.s.criteria[i].criteria.s.index = i;
            }
        }
    }
    _setCriteriaListeners(criteria) {
        criteria.dom.delete
            .off('click')
            .on('click.dtsb', () => {
            this._removeCriteria(criteria);
            criteria.dom.container.remove();
            for (const crit of this.s.criteria) {
                if (crit.criteria instanceof Criteria) {
                    crit.criteria.updateArrows(this.s.criteria.length > 1);
                }
            }
            criteria.destroy();
            //this.s.dt.draw();
            this.s.topGroup.trigger('dtsb-redrawContents');
            return false;
        });
        criteria.dom.right
            .off('click')
            .on('click.dtsb', () => {
            let idx = criteria.s.index;
            let group = new Group(this.s.queryBuilder, this.s.opts, this.s.topGroup, criteria.s.index, true, this.s.depth + 1, this.s.specialData);
            group.addCriteria(criteria);
            this.s.criteria[idx].criteria = group;
            this.s.criteria[idx].logic = 'AND';
            this.s.topGroup.trigger('dtsb-redrawContents');
            this._setGroupListeners(group);
            return false;
        });
        criteria.dom.left
            .off('click')
            .on('click.dtsb', () => {
            this.s.toDrop = new Criteria(this.s.queryBuilder, this.s.opts, this.s.topGroup, criteria.s.index, undefined);
            this.s.toDrop.s = criteria.s;
            this.s.toDrop.c = criteria.c;
            this.s.toDrop.classes = criteria.classes;
            this.s.toDrop.populate();
            let index = this.s.toDrop.s.index;
            this.dom.container.trigger('dtsb-dropCriteria');
            criteria.s.index = index;
            this._removeCriteria(criteria);
            this.s.topGroup.trigger('dtsb-redrawContents');
            //this.s.dt.draw();
            return false;
        });
    }
    _setClearListener() {
        this.dom.clear
            .off('click')
            .on('click.dtsb', () => {
            if (!this.s.isChild) {
                this.dom.container.trigger('dtsb-clearContents');
                return false;
            }
            this.destroy();
            this.s.topGroup.trigger('dtsb-redrawContents');
            return false;
        });
    }
    _setGroupListeners(group) {
        group.dom.add
            .off('click')
            .on('click.dtsb', () => {
            this.setupLogic();
            this.dom.container.trigger('dtsb-add');
            return false;
        });
        group.dom.container
            .off('dtsb-add')
            .on('dtsb-add.dtsb', () => {
            this.setupLogic();
            this.dom.container.trigger('dtsb-add');
            return false;
        });
        group.dom.container
            .off('dtsb-destroy')
            .on('dtsb-destroy.dtsb', () => {
            this._removeCriteria(group, true);
            group.dom.container.remove();
            this.setupLogic();
            return false;
        });
        group.dom.container
            .off('dtsb-dropCriteria')
            .on('dtsb-dropCriteria.dtsb', () => {
            let toDrop = group.s.toDrop;
            toDrop.s.index = group.s.index;
            toDrop.updateArrows(this.s.criteria.length > 1);
            this.addCriteria(toDrop);
            return false;
        });
        group.setListeners();
    }
    _setup() {
        this.setListeners();
        this.dom.add.html(this.c.i18n.add);
        this.dom.search.html(this.c.i18n.search);
        this.dom.logic.children().first().html(this.c.logic === 'OR'
            ? this.c.i18n.logicOr
            : this.c.logic === 'NOT'
                ? this.c.i18n.logicNot
                : this.c.i18n.logicAnd);
        this.s.logic =
            this.c.logic === 'OR'
                ? 'OR'
                : this.c.logic === 'NOT'
                    ? 'NOT'
                    : 'AND';
        if (this.c.greyscale) {
            this.dom.logic.addClass(this.classes.greyscale);
        }
        this.dom.logicContainer.append(this.dom.logic).append(this.dom.clear);
        if (this.s.isChild) {
            this.dom.container.append(this.dom.logicContainer);
        }
        this.dom.container.append(this.dom.add);
        if (!this.c.liveSearch) {
            this.dom.container.append(this.dom.search);
        }
    }
    _setLogicListener() {
        this.dom.logic
            .off('click')
            .on('click.dtsb', () => {
            this._toggleLogic();
            for (const crit of this.s.criteria) {
                crit.criteria.setListeners();
            }
        });
    }
    _toggleLogic() {
        let logic, html;
        switch (this.s.logic) {
            case 'AND':
                logic = 'OR';
                html = this.c.i18n.logicOr;
                break;
            case 'OR':
                logic = 'NOT';
                html = this.c.i18n.logicNot;
                break;
            case 'NOT':
                logic = 'AND';
                html = this.c.i18n.logicAnd;
                break;
        }
        this.s.logic = logic;
        this.dom.logic.children().first().html(html);
    }
}
Group.classes = {
    add: 'dtsb-add',
    button: 'dtsb-button',
    clearGroup: 'dtsb-clearGroup',
    greyscale: 'dtsb-greyscale',
    group: 'dtsb-group',
    inputButton: 'dtsb-iptbtn',
    logic: 'dtsb-logic',
    logicContainer: 'dtsb-logicContainer',
    search: 'dtsb-search'
};
Group.defaults = {
    conditions: {
        'tag': Criteria.tagConditions,
        'word-length': Criteria.wordLengthConditions,
        'word-pattern': Criteria.wordPatternConditions
    },
    depthLimit: false,
    enterSearch: false,
    greyscale: false,
    liveSearch: true,
    i18n: {
        add: 'Add Condition',
        button: {
            0: 'Search Builder',
            _: 'Search Builder (%d)',
        },
        clearAll: 'Clear All',
        condition: 'Condition',
        data: 'Filter',
        delete: '&times',
        deleteTitle: 'Delete filtering rule',
        left: '<',
        leftTitle: 'Outdent criteria',
        logicAnd: 'And',
        logicOr: 'Or',
        logicNot: 'Not',
        right: '>',
        rightTitle: 'Indent criteria',
        search: 'Search',
        title: {
            0: 'Custom Search Builder',
            _: 'Custom Search Builder (%d)',
        },
        value: 'Value',
        valueJoiner: 'and'
    },
    logic: 'AND',
    orthogonal: {
        display: 'display',
        search: 'filter',
    },
    preDefined: false,
    queryBuilt: undefined
};
export default Group;
