/*! SearchBuilder 1.8.1
 * ©SpryMedia Ltd - datatables.net/license/mit
 */
import * as util from './util';
let $;
export function setJQuery(jq) {
    $ = jq;
}
class Criteria {
    constructor(queryBuilder, opts, topGroup, index = 0, depth = 1, specialData = undefined, liveSearch = false) {
        this.classes = $.extend(true, {}, Criteria.classes);
        this.c = $.extend(true, {}, Criteria.defaults, opts);
        const i18n = this.c.i18n;
        this.s = {
            condition: undefined,
            conditions: {},
            data: undefined,
            dataIdx: -1,
            dataPoints: [],
            dateFormat: false,
            depth,
            filled: false,
            filterType: undefined,
            index,
            liveSearch: liveSearch,
            origData: undefined,
            preventRedraw: false,
            queryBuilder,
            specialData,
            topGroup,
            type: '',
            value: [],
        };
        this.dom = {
            buttons: $('<div/>')
                .addClass(this.classes.buttonContainer),
            condition: $('<select disabled/>')
                .addClass(this.classes.condition)
                .addClass(this.classes.dropDown)
                .addClass(this.classes.italic)
                .attr('autocomplete', 'hacking'),
            conditionTitle: $('<option disabled selected hidden/>')
                .html(i18n.condition),
            container: $('<div/>')
                .addClass(this.classes.container),
            data: $('<select/>')
                .addClass(this.classes.data)
                .addClass(this.classes.dropDown)
                .addClass(this.classes.italic),
            dataTitle: $('<option disabled selected hidden/>')
                .html(i18n.data),
            delete: $('<button/>')
                .html(i18n.delete)
                .addClass(this.classes.delete)
                .addClass(this.classes.button)
                .attr('title', i18n.deleteTitle)
                .attr('type', 'button'),
            inputCont: $('<div/>')
                .addClass(this.classes.inputCont),
            left: $('<button/>')
                .html(i18n.left)
                .addClass(this.classes.left)
                .addClass(this.classes.button)
                .attr('title', i18n.leftTitle)
                .attr('type', 'button'),
            right: $('<button/>')
                .html(i18n.right)
                .addClass(this.classes.right)
                .addClass(this.classes.button)
                .attr('title', i18n.rightTitle)
                .attr('type', 'button'),
            value: [
                $('<select disabled/>')
                    .addClass(this.classes.value)
                    .addClass(this.classes.dropDown)
                    .addClass(this.classes.italic)
                    .addClass(this.classes.select)
            ],
            valueTitle: $('<option value="--valueTitle--" disabled selected hidden/>')
                .html(i18n.value)
        };
        if (this.c.greyscale) {
            this.dom.data.addClass(this.classes.greyscale);
            this.dom.condition.addClass(this.classes.greyscale);
            for (let val of this.dom.value) {
                val.addClass(this.classes.greyscale);
            }
        }
        $(window).on('resize.dtsb', util.throttle(() => {
            this.s.topGroup.trigger('dtsb-redrawLogic');
        }));
        this._buildCriteria();
        return this;
    }
    static _escapeHTML(txt) {
        return txt
            .toString()
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&amp;/g, '&');
    }
    static initTagSelect(that, fn, preDefined = null) {
        that.dom.valueTitle.prop('selected', true);
        let el = $('<select/>')
            .addClass(Criteria.classes.value)
            .addClass(Criteria.classes.dropDown)
            .addClass(Criteria.classes.italic)
            .addClass(Criteria.classes.select)
            .append(that.dom.valueTitle)
            .on('change.dtsb', function () {
            $(this).removeClass(Criteria.classes.italic);
            fn(that, this);
        });
        if (that.c.greyscale) {
            el.addClass(Criteria.classes.greyscale);
        }
        let options = that.s.specialData.tags.map((tag) => {
            const opt = $('<option>', {
                type: 'tag',
                value: tag.id
            })
                .data('sbv', tag.id)
                .addClass(that.classes.option)
                .addClass(that.classes.notItalic)
                .html(tag.displayName.replace(/(<([^>]+)>)/ig, ''));
            if (preDefined !== null && opt.val() === preDefined[0]) {
                opt.prop('selected', true);
                el.removeClass(Criteria.classes.italic);
                that.dom.valueTitle.removeProp('selected');
            }
            return opt;
        });
        options.sort((a, b) => a.text().localeCompare(b.text()));
        for (let opt of options) {
            el.append(opt);
        }
        return el;
    }
    static initWordPattern(that, fn, preDefined = null) {
        let el = $('<input/>')
            .addClass(Criteria.classes.value)
            .addClass(Criteria.classes.input)
            .on('input.dtsb keypress.dtsb', that._throttle(function (e) {
            let code = e.keyCode || e.which;
            return fn(that, this, code);
        }, 50));
        if (that.c.greyscale) {
            el.addClass(Criteria.classes.greyscale);
        }
        if (preDefined !== null) {
            el.val(preDefined[0]);
        }
        /*
        that.s.dt.one('draw.dtsb', () => {
            that.s.topGroup.trigger('dtsb-redrawLogic');
        });
        */
        return el;
    }
    static initWordLength(that, fn, preDefined = null) {
        let el = $('<input/>')
            .addClass(Criteria.classes.value)
            .addClass(Criteria.classes.input)
            .on('input.dtsb keypress.dtsb', that._throttle(function (e) {
            let code = e.keyCode || e.which;
            return fn(that, this, code);
        }, 50));
        if (that.c.greyscale) {
            el.addClass(Criteria.classes.greyscale);
        }
        if (preDefined !== null) {
            el.val(preDefined[0]);
        }
        /*
        that.s.dt.one('draw.dtsb', () => {
            that.s.topGroup.trigger('dtsb-redrawLogic');
        });
        */
        return el;
    }
    static initWordLength2(that, fn, preDefined = null) {
        const searchDelay = 50;
        let els = [
            $('<input/>')
                .addClass(Criteria.classes.value)
                .addClass(Criteria.classes.input)
                .on('change.dtsb', searchDelay !== null ?
                util.throttle(function () {
                    return fn(that, this);
                }, searchDelay) :
                (e) => {
                    fn(that, e.target);
                })
                .on('input.dtsb keypress.dtsb', (e) => {
                util.throttle(function () {
                    let code = e.keyCode || e.which;
                    return fn(that, this, code);
                }, searchDelay === null ? 0 : searchDelay);
            }),
            $('<span>')
                .addClass(that.classes.joiner)
                .html(that.c.i18n.valueJoiner),
            $('<input/>')
                .addClass(Criteria.classes.value)
                .addClass(Criteria.classes.input)
                .on('change.dtsb', searchDelay !== null ?
                util.throttle(function () {
                    return fn(that, this);
                }, searchDelay) :
                (e) => {
                    fn(that, e.target);
                })
                .on('input.dtsb keypress.dtsb', !that.c.enterSearch
                && searchDelay !== null ?
                util.throttle(function () {
                    return fn(that, this);
                }, searchDelay) :
                (e) => {
                    let code = e.keyCode || e.which;
                    fn(that, e.target, code);
                })
        ];
        if (that.c.greyscale) {
            els[0].addClass(Criteria.classes.greyscale);
            els[2].addClass(Criteria.classes.greyscale);
        }
        if (preDefined !== null && preDefined.length > 0) {
            els[0].val(preDefined[0]);
            els[2].val(preDefined[1]);
        }
        // This is add responsive functionality to the logic button without redrawing everything else
        /*
        that.s.dt.one('draw.dtsb', () => {
            that.s.topGroup.trigger('dtsb-redrawLogic');
        });
        */
        return els;
    }
    static initNoValue(that) {
        return [];
    }
    static isInputValidSelect(el) {
        let allFilled = true;
        for (const element of el) {
            if (element.children('option:selected').length ===
                element.children('option').length -
                    element.children('option.' + Criteria.classes.notItalic).length &&
                element.children('option:selected').length === 1 &&
                element.children('option:selected')[0] === element.children('option')[0]) {
                allFilled = false;
            }
        }
        return allFilled;
    }
    static isInputValidInput(el) {
        let allFilled = true;
        for (const element of el) {
            if (element.is('input')) {
                const arr = element.val();
                if (arr !== null && arr.length === 0) {
                    allFilled = false;
                }
            }
        }
        return allFilled;
    }
    static inputValueVoid() {
        return [];
    }
    static inputValueSelect(el) {
        const values = [];
        for (const element of el) {
            if (element.is('select')) {
                values.push(Criteria._escapeHTML(element.children('option:selected').data('sbv')));
            }
        }
        return values;
    }
    static inputValueInput(el) {
        const values = [];
        for (const element of el) {
            if (element.is('input')) {
                values.push(Criteria._escapeHTML(element.val().toString()));
            }
        }
        return values;
    }
    static updateListener(that, el, code) {
        let condition = that.s.conditions[that.s.condition];
        let i;
        that.s.filled = condition.isInputValid(that.dom.value, that);
        that.s.value = condition.inputValue(that.dom.value);
        /*
        if (!that.s.filled) {
            if (
                !that.c.enterSearch &&
                !(
                    that.s.dt.settings()[0].oInit.search !== undefined &&
                    that.s.dt.settings()[0].oInit.search.return
                ) ||
                code === 13
            ) {
                that.doBuildQuery();
            }
            return;
        }
        */
        if (!Array.isArray(that.s.value)) {
            that.s.value = [that.s.value];
        }
        for (i = 0; i < that.s.value.length; i++) {
            // If the value is an array we need to sort it
            if (Array.isArray(that.s.value[i])) {
                that.s.value[i].sort();
            }
        }
        let idx = null;
        let cursorPos = null;
        for (i = 0; i < that.dom.value.length; i++) {
            if (el === that.dom.value[i][0]) {
                idx = i;
                const input = el;
                if (input !== null && input.selectionStart !== undefined) {
                    cursorPos = input.selectionStart;
                }
            }
        }
        /*
        if (
            !that.c.enterSearch &&
            !(
                that.s.dt.settings()[0].oInit.search !== undefined &&
                that.s.dt.settings()[0].oInit.search.return
            ) ||
            code === 13
        ) {
            // Trigger a search
            that.doBuildQuery();
        }
        */
        // Refocus the element and set the correct cursor position
        if (idx !== null) {
            that.dom.value[idx].removeClass(that.classes.italic);
            that.dom.value[idx].trigger('focus');
            if (cursorPos !== null) {
                const input = that.dom.value[idx][0];
                if (input !== null) {
                    input.setSelectionRange(cursorPos, cursorPos);
                }
            }
        }
    }
    doBuildQuery() {
        if (this.c.liveSearch) {
            this.c.queryBuilt(this.s.queryBuilder.buildQuery());
        }
    }
    ;
    updateArrows(hasSiblings = false) {
        this.dom.container.children().detach();
        this.dom.container
            .append(this.dom.data)
            .append(this.dom.condition)
            .append(this.dom.inputCont);
        this.setListeners();
        if (this.dom.value[0] !== undefined) {
            this.dom.value[0].trigger('dtsb-inserted');
        }
        for (let i = 1, iend = this.dom.value.length; i < iend; i++) {
            let el = this.dom.value[i];
            this.dom.inputCont.append(el);
            el.trigger('dtsb-inserted');
        }
        if (this.s.depth > 1) {
            this.dom.buttons.append(this.dom.left);
        }
        if ((this.c.depthLimit === false || this.s.depth < this.c.depthLimit) && hasSiblings) {
            this.dom.buttons.append(this.dom.right);
        }
        else {
            this.dom.right.remove();
        }
        this.dom.buttons.append(this.dom.delete);
        this.dom.container.append(this.dom.buttons);
    }
    destroy() {
        this.dom.data.off('.dtsb');
        this.dom.condition.off('.dtsb');
        this.dom.delete.off('.dtsb');
        for (const val of this.dom.value) {
            val.off('.dtsb');
        }
        this.dom.container.remove();
    }
    getDetails() {
        return {
            condition: this.s.condition,
            data: this.s.data,
            origData: this.s.origData,
            type: this.s.type,
            value: this.s.value.flatMap(a => a !== null && a !== undefined ? [a.toString()] : [])
        };
    }
    buildQuery() {
        if (!this.s.filled) {
            return null;
        }
        switch (this.s.filterType) {
            case 'tag':
                switch (this.s.condition) {
                    case '=':
                        return 'ti:' + this.s.value[0];
                    case 'null':
                        return 'nt';
                    default:
                        return null;
                }
            case 'word-length':
                switch (this.s.condition) {
                    case '=':
                        return 'wl:' + this.s.value[0];
                    case '>=':
                        return 'wl:' + this.s.value[0] + '..';
                    case '<=':
                        return 'wl:..' + this.s.value[0];
                    case 'between':
                        return 'wl:' + this.s.value[0] + '..' + this.s.value[1];
                    default:
                        return null;
                }
            case 'word-pattern':
                return 'wp:' + this.s.value[0];
        }
    }
    getNode() {
        return this.dom.container;
    }
    parseNumber(val) {
        //var decimal = this.s.dt.i18n('decimal');
        var decimal = '';
        // Remove any periods and then replace the decimal with a period
        if (decimal && decimal !== '.') {
            val = val.replace(/\./g, '').replace(decimal, '.');
        }
        return +val.replace(/(?!^-)[^0-9.]/g, '');
    }
    populate() {
        this._populateFilterType();
        if (this.s.dataIdx !== -1) {
            this._populateCondition();
            if (this.s.condition !== undefined) {
                this._populateValue();
            }
        }
    }
    rebuild(loadedCriteria) {
        let foundData = false;
        let dataIdx;
        this._populateFilterType();
        if (loadedCriteria.data !== undefined) {
            const italic = this.classes.italic;
            const data = this.dom.data;
            const options = data.children('option');
            for (const el of options) {
                const opt = $(el);
                if (!foundData
                    && (opt.text() === loadedCriteria.data
                        || loadedCriteria.origData && opt.prop('origData') === loadedCriteria.origData)) {
                    opt.prop('selected', true);
                    data.removeClass(italic);
                    foundData = true;
                    dataIdx = parseInt(opt.val(), 10);
                }
                else {
                    opt.removeProp('selected');
                }
            }
        }
        if (foundData) {
            this.s.data = loadedCriteria.data;
            this.s.origData = loadedCriteria.origData;
            this.s.dataIdx = dataIdx;
            this.c.orthogonal = this._getOptions().orthogonal;
            this.dom.dataTitle.remove();
            this._populateCondition();
            this.dom.conditionTitle.remove();
            let condition;
            const options = this.dom.condition.children('option');
            for (const el of options) {
                const opt = $(el);
                if (loadedCriteria.condition !== undefined
                    && opt.val() === loadedCriteria.condition
                    && typeof loadedCriteria.condition === 'string') {
                    opt.prop('selected', true);
                    condition = opt.val();
                }
                else {
                    opt.removeProp('selected');
                }
            }
            this.s.condition = condition;
            if (this.s.condition !== undefined) {
                this.dom.conditionTitle.removeProp('selected');
                this.dom.conditionTitle.remove();
                this.dom.condition.removeClass(this.classes.italic);
                for (const el of options) {
                    const opt = $(el);
                    if (opt.val() !== this.s.condition) {
                        opt.removeProp('selected');
                    }
                }
                this._populateValue(loadedCriteria);
            }
            else {
                this.dom.conditionTitle.prependTo(this.dom.condition).prop('selected', true);
            }
        }
    }
    setListeners() {
        this.dom.data
            .off('change')
            .on('change.dtsb', () => {
            this.dom.dataTitle.removeProp('selected');
            const options = this.dom.data.children('option.' + this.classes.option);
            for (const el of options) {
                let opt = $(el);
                if (opt.val() === this.dom.data.val()) {
                    this.dom.data.removeClass(this.classes.italic);
                    opt.prop('selected', true);
                    this.s.dataIdx = +opt.val();
                    this.s.data = opt.text();
                    this.s.origData = opt.prop('origData');
                    this.s.filterType = opt.val().toString();
                    this.c.orthogonal = this._getOptions().orthogonal;
                    this._clearCondition();
                    this._clearValue();
                    this._populateCondition();
                    if (this.s.filled) {
                        this.s.filled = false;
                        this.doBuildQuery();
                        this.setListeners();
                    }
                    //this.s.dt.state.save();
                }
                else {
                    opt.removeProp('selected');
                }
            }
        });
        this.dom.condition
            .off('change')
            .on('change.dtsb', () => {
            this.dom.conditionTitle.removeProp('selected');
            const options = this.dom.condition.children('option.' + this.classes.option);
            for (const el of options) {
                let opt = $(el);
                if (opt.val() === this.dom.condition.val()) {
                    this.dom.condition.removeClass(this.classes.italic);
                    opt.prop('selected', true);
                    let condDisp = opt.val();
                    for (const cond of Object.keys(this.s.conditions)) {
                        if (cond === condDisp) {
                            this.s.condition = condDisp;
                            break;
                        }
                    }
                    this._clearValue();
                    this._populateValue();
                    for (const val of this.dom.value) {
                        if (this.s.filled && val !== undefined && this.dom.inputCont.has(val[0]).length !== 0) {
                            this.s.filled = false;
                            this.doBuildQuery();
                            this.setListeners();
                        }
                    }
                    if (this.dom.value.length === 0
                        || this.dom.value.length === 1 && this.dom.value[0] === undefined) {
                        this.doBuildQuery();
                    }
                }
                else {
                    opt.removeProp('selected');
                }
            }
        });
    }
    setupButtons() {
        if (window.innerWidth > 550) {
            this.dom.container.removeClass(this.classes.vertical);
            this.dom.buttons.css('left', null);
            this.dom.buttons.css('top', null);
            return;
        }
        this.dom.container.addClass(this.classes.vertical);
        this.dom.buttons.css('left', this.dom.data.innerWidth());
        this.dom.buttons.css('top', this.dom.data.position().top);
    }
    _buildCriteria() {
        this.dom.data.append(this.dom.dataTitle);
        this.dom.condition.append(this.dom.conditionTitle);
        this.dom.container
            .append(this.dom.data)
            .append(this.dom.condition);
        this.dom.inputCont.empty();
        for (const val of this.dom.value) {
            val.append(this.dom.valueTitle);
            this.dom.inputCont.append(val);
        }
        this.dom.buttons
            .append(this.dom.delete)
            .append(this.dom.right);
        this.dom.container.append(this.dom.inputCont).append(this.dom.buttons);
        this.setListeners();
    }
    _clearCondition() {
        this.dom.condition.empty();
        this.dom.conditionTitle.prop('selected', true).attr('disabled', 'true');
        this.dom.condition.prepend(this.dom.conditionTitle).prop('selectedIndex', 0);
        this.s.conditions = {};
        this.s.condition = undefined;
    }
    _clearValue() {
        if (this.s.condition !== undefined) {
            if (this.dom.value.length > 0 && this.dom.value[0] !== undefined) {
                for (const el of this.dom.value) {
                    if (el !== undefined) {
                        setTimeout(function () {
                            el.remove();
                        }, 50);
                    }
                }
            }
            this.dom.value = [].concat(this.s.conditions[this.s.condition].init(this, Criteria.updateListener));
            if (this.dom.value.length > 0 && this.dom.value[0] !== undefined) {
                this.dom.inputCont
                    .empty()
                    .append(this.dom.value[0])
                    .insertAfter(this.dom.condition);
                $(this.dom.value[0]).trigger('dtsb-inserted');
                for (let i = 1, iend = this.dom.value.length; i < iend; i++) {
                    const el = this.dom.value[i];
                    this.dom.inputCont.append(el);
                    el.trigger('dtsb-inserted');
                }
            }
        }
        else {
            for (const el of this.dom.value) {
                if (el !== undefined) {
                    setTimeout(function () {
                        el.remove();
                    }, 50);
                }
            }
            this.dom.valueTitle.prop('selected', true);
        }
        this.s.value = [];
        this.dom.value = [
            $('<select disabled/>')
                .addClass(this.classes.value)
                .addClass(this.classes.dropDown)
                .addClass(this.classes.italic)
                .addClass(this.classes.select)
                .append(this.dom.valueTitle.clone())
        ];
    }
    _getOptions() {
        return $.extend(true, {}, Criteria.defaults);
    }
    _populateCondition() {
        let conditionOpts = [];
        let conditionsLength = Object.keys(this.s.conditions).length;
        let column = +this.dom.data.children('option:selected').val();
        let condition, condName;
        if (conditionsLength === 0) {
            this.s.type = this.s.filterType;
            this.dom.condition
                .removeAttr('disabled')
                .empty()
                .append(this.dom.conditionTitle)
                .addClass(this.classes.italic);
            this.dom.conditionTitle
                .prop('selected', true);
            let conditionObj;
            if (this.c.conditions[this.s.type] !== undefined) {
                conditionObj = this.c.conditions[this.s.type];
            }
            else {
                conditionObj = this.c.conditions.string;
            }
            for (condition of Object.keys(conditionObj)) {
                if (conditionObj[condition] !== null) {
                    this.s.conditions[condition] = conditionObj[condition];
                    condName = conditionObj[condition].conditionName;
                    if (typeof condName === 'function') {
                        condName = condName(this.c.i18n);
                    }
                    conditionOpts.push($('<option>', {
                        text: condName,
                        value: condition,
                    })
                        .addClass(this.classes.option)
                        .addClass(this.classes.notItalic));
                }
            }
        }
        else if (conditionsLength > 0) {
            this.dom.condition.empty().removeAttr('disabled').addClass(this.classes.italic);
            for (condition of Object.keys(this.s.conditions)) {
                let name = this.s.conditions[condition].conditionName;
                if (typeof name === 'function') {
                    name = name(this.c.i18n);
                }
                let newOpt = $('<option>', {
                    text: name,
                    value: condition
                })
                    .addClass(this.classes.option)
                    .addClass(this.classes.notItalic);
                if (this.s.condition !== undefined && this.s.condition === name) {
                    newOpt.prop('selected', true);
                    this.dom.condition.removeClass(this.classes.italic);
                }
                conditionOpts.push(newOpt);
            }
        }
        else {
            this.dom.condition
                .attr('disabled', 'true')
                .addClass(this.classes.italic);
            return;
        }
        for (let opt of conditionOpts) {
            this.dom.condition.append(opt);
        }
        // Selecting a default condition if one is set
        /*
        if(colInits[column].searchBuilder && colInits[column].searchBuilder.defaultCondition) {
            let defaultCondition = colInits[column].searchBuilder.defaultCondition;

            // If it is a number just use it as an index
            if (typeof defaultCondition === 'number') {
                this.dom.condition.prop('selectedIndex', defaultCondition);
                this.dom.condition.trigger('change');
            }
            // If it is a string then things get slightly more tricly
            else if (typeof defaultCondition === 'string') {
                // We need to check each condition option to see if any will match
                for (let i = 0; i < conditionOpts.length; i++) {
                    // Need to check against the stored conditions so we can match the token "cond" to the option
                    for (let cond of Object.keys(this.s.conditions)) {
                        condName = this.s.conditions[cond].conditionName;

                        if (
                            // If the conditionName matches the text of the option
                            (typeof condName === 'string' ? condName : condName(dt, this.c.i18n)) ===
                            conditionOpts[i].text() &&
                            // and the tokens match
                            cond === defaultCondition
                        ) {
                            // Select that option
                            this.dom.condition
                                .prop(
                                    'selectedIndex',
                                    this.dom.condition.children().toArray().indexOf(conditionOpts[i][0])
                                )
                                .removeClass(this.classes.italic);
                            this.dom.condition.trigger('change');
                            i = conditionOpts.length;
                            break;
                        }
                    }
                }
            }
        }
        // If not default set then default to 0, the title
        else {
            this.dom.condition.prop('selectedIndex', 0);
        }
        */
    }
    _populateFilterType() {
        const data = Criteria.filters.map(filter => ({
            text: typeof filter.filterName === 'function'
                ? filter.filterName(this.c.i18n)
                : filter.filterName,
            value: filter.filter,
        }));
        this.dom.data.empty().append(this.dom.dataTitle);
        for (const item of data) {
            const selected = this.s.filterType === item.value;
            this.dom.data.append($('<option>', {
                text: item.text,
                value: item.value,
            })
                .addClass(this.classes.option)
                .addClass(this.classes.notItalic)
                .prop('selected', selected));
            if (selected) {
                this.dom.dataTitle.removeProp('selected');
            }
        }
    }
    _populateValue(loadedCriteria) {
        let prevFilled = this.s.filled;
        this.s.filled = false;
        for (const el of this.dom.value) {
            setTimeout(function () {
                if (el !== undefined) {
                    el.remove();
                }
            }, 50);
        }
        const children = this.dom.inputCont.children();
        if (children.length > 1) {
            for (const el of children) {
                $(el).remove();
            }
        }
        this.dom.value = [].concat(this.s.conditions[this.s.condition].init(this, Criteria.updateListener, loadedCriteria !== undefined ? loadedCriteria.value : undefined));
        if (loadedCriteria !== undefined && loadedCriteria.value !== undefined) {
            this.s.value = loadedCriteria.value;
        }
        this.dom.inputCont.empty();
        if (this.dom.value[0] !== undefined) {
            this.dom.value[0]
                .appendTo(this.dom.inputCont)
                .trigger('dtsb-inserted');
        }
        for (let i = 1, iend = this.dom.value.length; i < iend; i++) {
            this.dom.value[i]
                .insertAfter(this.dom.value[i - 1])
                .trigger('dtsb-inserted');
        }
        this.s.filled = this.s.conditions[this.s.condition].isInputValid(this.dom.value, this);
        this.setListeners();
        if (!this.s.preventRedraw && prevFilled !== this.s.filled) {
            /*
            if (!this.s.dt.page.info().serverSide) {
                this.doBuildQuery();
            }
            */
            this.setListeners();
        }
    }
    _throttle(fn, freq = 200) {
        const frequency = freq !== undefined ? freq : 200;
        let last = undefined;
        let timer = null;
        let that = this;
        return function () {
            const args = arguments;
            let now = +new Date();
            if (last !== undefined && now < last + frequency) {
                clearTimeout(timer);
            }
            else {
                last = now;
            }
            timer = setTimeout(function () {
                last = undefined;
                fn.apply(that, args);
            }, frequency);
        };
    }
}
Criteria.classes = {
    button: 'dtsb-button',
    buttonContainer: 'dtsb-buttonContainer',
    condition: 'dtsb-condition',
    container: 'dtsb-criteria',
    data: 'dtsb-data',
    delete: 'dtsb-delete',
    dropDown: 'dtsb-dropDown',
    greyscale: 'dtsb-greyscale',
    input: 'dtsb-input',
    inputCont: 'dtsb-inputCont',
    italic: 'dtsb-italic',
    joiner: 'dtsb-joiner',
    left: 'dtsb-left',
    notItalic: 'dtsb-notItalic',
    option: 'dtsb-option',
    right: 'dtsb-right',
    select: 'dtsb-select',
    value: 'dtsb-value',
    vertical: 'dtsb-vertical'
};
Criteria.tagConditions = {
    '=': {
        conditionName: i18n => i18n.conditions.tag.equals,
        init: Criteria.initTagSelect,
        inputValue: Criteria.inputValueSelect,
        isInputValid: Criteria.isInputValidSelect,
    },
    'null': {
        conditionName: i18n => i18n.conditions.tag.empty,
        init: Criteria.initNoValue,
        inputValue: Criteria.inputValueVoid,
        isInputValid: () => true,
    }
};
Criteria.wordLengthConditions = {
    '=': {
        conditionName: i18n => i18n.conditions.wordLength.equals,
        init: Criteria.initWordLength,
        inputValue: Criteria.inputValueInput,
        isInputValid: Criteria.isInputValidInput,
    },
    '>=': {
        conditionName: i18n => i18n.conditions.wordLength.gte,
        init: Criteria.initWordLength,
        inputValue: Criteria.inputValueInput,
        isInputValid: Criteria.isInputValidInput,
    },
    '<=': {
        conditionName: i18n => i18n.conditions.wordLength.lte,
        init: Criteria.initWordLength,
        inputValue: Criteria.inputValueInput,
        isInputValid: Criteria.isInputValidInput,
    },
    'between': {
        conditionName: i18n => i18n.conditions.wordLength.between,
        init: Criteria.initWordLength2,
        inputValue: Criteria.inputValueInput,
        isInputValid: Criteria.isInputValidInput,
    }
};
Criteria.wordPatternConditions = {
    'is': {
        conditionName: i18n => i18n.conditions.wordPattern.is,
        init: Criteria.initWordPattern,
        inputValue: Criteria.inputValueInput,
        isInputValid: Criteria.isInputValidInput,
    }
};
Criteria.defaults = {
    conditions: {
        'tag': Criteria.tagConditions,
        'word-length': Criteria.wordLengthConditions,
        'word-pattern': Criteria.wordPatternConditions
    },
    depthLimit: false,
    enterSearch: false,
    greyscale: false,
    i18n: {
        add: 'Add Condition',
        button: {
            0: 'Search Builder',
            _: 'Search Builder (%d)',
        },
        clearAll: 'Clear All',
        condition: 'Condition',
        data: 'Filter',
        delete: '&times',
        deleteTitle: 'Delete filtering rule',
        left: '<',
        leftTitle: 'Outdent criteria',
        logicAnd: 'And',
        logicOr: 'Or',
        logicNot: 'Not',
        right: '>',
        rightTitle: 'Indent criteria',
        search: 'Search',
        title: {
            0: 'Custom Search Builder',
            _: 'Custom Search Builder (%d)',
        },
        value: 'Value',
        valueJoiner: 'and'
    },
    liveSearch: true,
    logic: 'AND',
    orthogonal: {
        display: 'display',
        search: 'filter'
    },
    preDefined: false,
    queryBuilt: undefined
};
Criteria.filters = [
    {
        filter: 'tag',
        filterName: i18n => i18n.filters.tag,
    },
    {
        filter: 'word-pattern',
        filterName: i18n => i18n.filters.wordPattern,
    },
    {
        filter: 'word-length',
        filterName: i18n => i18n.filters.wordLength,
    }
];
export default Criteria;
