var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { url } from './utils';
function makeState(model) {
    const name = model.pagination != null ? model.pagination.name : null;
    const params = {
        n: name,
        e: name != null ? true : undefined,
        l: model.limit,
        d: model.desc ? true : undefined
    };
    return { params: params };
}
function makeDatatable(options) {
    return $('#tags-search-result').dataTable(options);
}
function urlFromState(state) {
    return url(window.location.href, state.params);
}
export function setupTagList(model) {
    let first;
    let last;
    let pageEvent;
    let pageIndex = 0;
    let state = makeState(model);
    let table;
    window.addEventListener('popstate', e => {
        first = undefined;
        last = undefined;
        pageEvent = undefined;
        pageIndex = 0;
        state = e.state !== null ? e.state : makeState(model);
        if (table != null) {
            table.api().ajax.reload();
        }
    });
    jQuery(() => {
        var _a;
        document.addEventListener('click', e => {
            if ($(e.target).hasClass('page-link')) {
                pageEvent = e.target.getAttribute('data-dt-idx');
            }
        }, true);
        table = makeDatatable({
            pagingType: 'full',
            lengthChange: false,
            pageLength: (_a = model.limit) !== null && _a !== void 0 ? _a : 40,
            scrollY: 'calc(100vh - 20.5rem)',
            scrollCollapse: true,
            serverSide: true,
            processing: true,
            columns: [
                { data: 'id', title: 'Id', type: 'hidden', visible: false },
                { data: 'displayName', title: 'Tag' },
                { data: 'description', title: 'Description' }
            ],
            select: {
                blurable: true
            },
            altEditor: true,
            layout: {
                topStart: {
                    buttons: [
                        { name: 'add', text: '<i class="fas fa-fw fa-plus-circle fa-sm"></i>' },
                        { name: 'edit', text: '<i class="fas fa-fw fa-edit fa-sm"></i>', extend: 'selectedSingle' },
                        { name: 'delete', text: '<i class="fas fa-fw fa-trash fa-sm"></i>', extend: 'selected' },
                        { name: 'refresh', text: '<i class="fas fa-fw fa-sync-alt fa-sm"></i>' }
                    ]
                }
            },
            ajax: {
                url: '/api/tags',
                type: 'GET',
                cache: true,
                data: () => {
                    $('.dt-paging-button').addClass('disabled');
                    let push = false;
                    switch (pageEvent) {
                        case 'first':
                            push = true;
                            state.params.n = undefined;
                            state.params.e = undefined;
                            state.params.d = undefined;
                            break;
                        case 'previous':
                            push = true;
                            state.params.n = first;
                            state.params.e = first != null ? true : undefined;
                            state.params.d = true;
                            break;
                        case 'next':
                            push = true;
                            state.params.n = last;
                            state.params.e = last != null ? true : undefined;
                            state.params.d = undefined;
                            break;
                        case 'last':
                            push = true;
                            state.params.n = undefined;
                            state.params.e = undefined;
                            state.params.d = true;
                            break;
                    }
                    if (push) {
                        history.pushState(state, '', urlFromState(state));
                    }
                    pageEvent = undefined;
                    return state.params;
                },
                dataFilter: response => {
                    const json = JSON.parse(response);
                    const tags = json.tags;
                    const prev = json.prev;
                    const next = json.next;
                    if (tags.length > 0) {
                        first = tags[0].value;
                        last = tags[tags.length - 1].value;
                    }
                    const count = (!prev && !next) ? (model.limit) : (model.limit * 5);
                    pageIndex = prev ? (next ? 2 : 4) : 0;
                    return JSON.stringify({
                        recordsTotal: count,
                        recordsFiltered: count,
                        data: tags
                    });
                }
            },
            preDrawCallback: settings => (new $.fn.dataTable.Api(settings)).page(pageIndex),
            onAddRow: (_, rowdata, success, error) => {
                const { id } = rowdata, withoutId = __rest(rowdata, ["id"]);
                $.ajax({
                    url: '/api/tags',
                    type: 'POST',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(withoutId),
                    success: success,
                    error: error
                });
            },
            onEditRow: (_, rowdata, success, error) => {
                const { id } = rowdata, withoutId = __rest(rowdata, ["id"]);
                $.ajax({
                    url: `/api/tags/${id}`,
                    type: 'PUT',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(withoutId),
                    success: success,
                    error: error
                });
            },
            onDeleteRow: (_, rowdatas, success, error) => {
                const ids = rowdatas.map(rowdata => rowdata.id);
                $.ajax({
                    url: '/api/tags',
                    type: 'DELETE',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify({ tagIds: ids }),
                    success: success,
                    error: error
                });
            }
        });
    });
}
