/*! SearchBuilder 1.8.1
 * ©SpryMedia Ltd - datatables.net/license/mit
 */
import Criteria, * as criteria from './criteria';
import Group, * as group from './group';
import SearchBuilder, * as builder from './queryBuilder';
builder.setJQuery($);
group.setJQuery($);
criteria.setJQuery($);
let sb;
$.extend(true, SearchBuilder.classes, {
    clearAll: 'btn btn-light dtsb-clearAll'
});
$.extend(true, Group.classes, {
    add: 'btn btn-light dtsb-add',
    clearGroup: 'btn btn-light dtsb-clearGroup',
    logic: 'btn btn-light dtsb-logic',
    search: 'btn btn-light dtsb-search',
});
$.extend(true, Criteria.classes, {
    condition: 'form-control dtsb-condition',
    data: 'form-control dtsb-data',
    delete: 'btn btn-light dtsb-delete',
    left: 'btn btn-light dtsb-left',
    right: 'btn btn-light dtsb-right',
    value: 'form-control dtsb-value',
});
export function initQueryBuilder(opts) {
    sb = new SearchBuilder(opts);
    return sb;
}
export function showQueryBuilder(host, tableContainer, specialDataPromise) {
    if (!sb) {
        throw new Error('SearchBuilder not initialised');
    }
    popover(sb.getNode(), host, tableContainer);
    specialDataPromise
        .then(specialData => $.extend(true, sb.s.specialData, specialData))
        .then(() => {
        if (topGroup !== undefined) {
            topGroup.dom.container.trigger('dtsb-redrawContents-noDraw');
        }
    });
    const topGroup = sb.s.topGroup;
    if (topGroup !== undefined) {
        topGroup.dom.container.trigger('dtsb-redrawContents-noDraw');
    }
    if (topGroup.s.criteria.length === 0) {
        $('.' + Group.classes.add.replace(/ /g, '.')).trigger('click');
    }
}
function fadeIn(el, duration, fn) {
    if ($.fn.animate) {
        el.stop().fadeIn(duration, fn);
    }
    else {
        el.css('display', 'block');
        if (fn) {
            fn.call(el);
        }
    }
}
function fadeOut(el, duration, fn) {
    if ($.fn.animate) {
        el.stop().fadeOut(duration, fn);
    }
    else {
        el.css('display', 'none');
        if (fn) {
            fn.call(el);
        }
    }
}
function background(show, className, fade, insertPoint) {
    if (fade === undefined) {
        fade = 400;
    }
    if (!insertPoint) {
        insertPoint = document.body;
    }
    if (show) {
        fadeIn($('<div/>')
            .addClass(className)
            .css('display', 'none')
            .insertAfter(insertPoint), fade);
    }
    else {
        fadeOut($('div.' + className), fade, function () {
            $(this).removeClass(className).remove();
        });
    }
}
function popover(content, host, tableContainer) {
    let closed = false;
    const options = {
        align: 'container',
        autoClose: false,
        background: true,
        backgroundClassName: 'dt-button-background',
        closeButton: true,
        containerClassName: 'dt-button-collection',
        contentClassName: '',
        collectionLayout: '',
        collectionTitle: '',
        dropup: false,
        fade: 400,
        popoverTitle: '',
        rightAlignClassName: 'dt-button-right',
        span: 'container',
        tag: 'div'
    };
    const containerSelector = options.tag + '.' + options.containerClassName.replace(/ /g, '.');
    const hostButtonNode = host;
    let hostNode = host;
    const close = () => {
        closed = true;
        fadeOut($(containerSelector), options.fade, function () {
            $(this).detach();
        });
        $(host, '[aria-expanded="true"]').attr('aria-expanded', 'false');
        $('div.dt-button-background').off('click.dtb-collection');
        background(false, options.backgroundClassName, options.fade, hostNode);
        $(window).off('resize.resize.dtb-collection');
        host.off('buttons-action.b-internal').off('destroy');
        $('body').off('.dtb-collection').trigger('buttons-popover-hide.dt');
        if (sb.c.queryBuilt !== undefined) {
            sb.c.queryBuilt(sb.buildQuery());
        }
    };
    const cnt = $('.dt-button', content).length;
    let mod = '';
    if (cnt === 3) {
        mod = 'dtb-b3';
    }
    else if (cnt === 2) {
        mod = 'dtb-b2';
    }
    else if (cnt === 1) {
        mod = 'dtb-b1';
    }
    const display = $('<' + options.tag + '/>')
        .addClass(options.containerClassName)
        .addClass(options.collectionLayout)
        //.addClass(options.splitAlignClass)
        .addClass(mod)
        .css('display', 'none')
        .attr({
        'aria-modal': true,
        role: 'dialog'
    });
    content = $(content)
        .addClass(options.contentClassName)
        .attr('role', 'menu')
        .appendTo(display);
    hostButtonNode.attr('aria-expanded', 'true');
    if (hostNode.parents('body')[0] !== document.body) {
        hostNode = document.body.lastChild;
    }
    if (options.popoverTitle) {
        display.prepend('<div class="dt-button-collection-title">' +
            options.popoverTitle +
            '</div>');
    }
    else if (options.collectionTitle) {
        display.prepend('<div class="dt-button-collection-title">' +
            options.collectionTitle +
            '</div>');
    }
    if (options.closeButton) {
        display
            .prepend('<div class="dtb-popover-close">&times;</div>')
            .addClass('dtb-collection-closeable');
    }
    fadeIn(display.insertAfter(hostNode), options.fade);
    const position = display.css('position');
    if (options.span === 'container' || options.align === 'dt-container') {
        hostNode = hostNode.parent();
        display.css('width', tableContainer.width());
    }
    if (position === 'absolute') {
        const offsetParent = $(hostNode[0].offsetParent);
        const buttonPosition = hostNode.position();
        const buttonOffset = hostNode.offset();
        const tableSizes = offsetParent.offset();
        const containerPosition = offsetParent.position();
        let computed = window.getComputedStyle(offsetParent[0]);
        tableSizes.height = offsetParent.outerHeight();
        tableSizes.width =
            offsetParent.width() + parseFloat(computed.paddingLeft);
        tableSizes.right = tableSizes.left + tableSizes.width;
        tableSizes.bottom = tableSizes.top + tableSizes.height;
        let top = buttonPosition.top + hostNode.outerHeight();
        let left = buttonPosition.left;
        display.css({
            top: top,
            left: left
        });
        computed = window.getComputedStyle(display[0]);
        const popoverSizes = display.offset();
        popoverSizes.height = display.outerHeight();
        popoverSizes.width = display.outerWidth();
        popoverSizes.right = popoverSizes.left + popoverSizes.width;
        popoverSizes.bottom = popoverSizes.top + popoverSizes.height;
        popoverSizes.marginTop = parseFloat(computed.marginTop);
        popoverSizes.marginBottom = parseFloat(computed.marginBottom);
        if (options.dropup) {
            top =
                buttonPosition.top -
                    popoverSizes.height -
                    popoverSizes.marginTop -
                    popoverSizes.marginBottom;
        }
        if (options.align === 'button-right'
            || display.hasClass(options.rightAlignClassName)) {
            left =
                buttonPosition.left -
                    popoverSizes.width +
                    hostNode.outerWidth();
        }
        if (options.align === 'dt-container'
            || options.align === 'container') {
            if (left < buttonPosition.left) {
                left = -buttonPosition.left;
            }
        }
        if (containerPosition.left + left + popoverSizes.width > $(window).width()) {
            left =
                $(window).width() -
                    popoverSizes.width -
                    containerPosition.left;
        }
        if (buttonOffset.left + left < 0) {
            left = -buttonOffset.left;
        }
        if (containerPosition.top + top + popoverSizes.height >
            $(window).height() + $(window).scrollTop()) {
            top =
                buttonPosition.top -
                    popoverSizes.height -
                    popoverSizes.marginTop -
                    popoverSizes.marginBottom;
        }
        if (offsetParent.offset().top + top < $(window).scrollTop()) {
            top = buttonPosition.top + hostNode.outerHeight();
        }
        display.css({
            top: top,
            left: left
        });
    }
    else {
        const place = function () {
            const half = $(window).height() / 2;
            let top = display.height() / 2;
            if (top > half) {
                top = half;
            }
            display.css('marginTop', top * -1);
        };
        place();
        $(window).on('resize.dtb-collection', function () {
            place();
        });
    }
    if (options.background) {
        background(true, options.backgroundClassName, options.fade, hostNode);
    }
    $('div.dt-button-background').on('click.dtb-collection', function () {
    });
    $(display).trigger('buttons-popover.dt');
    host.on('destroy', close);
    setTimeout(function () {
        closed = false;
        $('body')
            .on('click.dtb-collection', function (e) {
            if (closed) {
                return;
            }
            const parent = $(e.target).parent()[0];
            if ((!$(e.target).parents().addBack().filter(content).length
                && !$(parent).hasClass('dt-buttons'))
                || $(e.target).hasClass('dt-button-background')) {
                close();
            }
        })
            .on('keyup.dtb-collection', function (e) {
            const ke = e.originalEvent;
            if (ke !== null && ke.code === 'Escape') {
                close();
            }
        })
            .on('keydown.dtb-collection', function (e) {
            // Focus trap for tab key
            const elements = $('a, button', content);
            const active = document.activeElement;
            const ke = e.originalEvent;
            if (ke !== null && ke.code !== 'Tab') {
                return;
            }
            if (elements.index(active) === -1) {
                elements.first().trigger('focus');
                e.preventDefault();
            }
            else if (e.shiftKey) {
                if (active === elements[0]) {
                    elements.last().trigger('focus');
                    e.preventDefault();
                }
            }
            else {
                if (active === elements.last()[0]) {
                    elements.first().trigger('focus');
                    e.preventDefault();
                }
            }
        });
    }, 0);
}
